.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.activeLink {
  display: flex;
  padding-inline: 10px;
  padding-block: 4px;
  align-items: center;
  gap: 7px;
  color: #000;
  font-size: medium;
  font-weight: 600;
}

.activeLink:hover {
  background-color: blue;
  color: white;
}

/* For Toast Progress bar left to right */

.Toastify__progress-bar--animated {
  animation: customTrackProgress linear 2s forwards !important;
}

@keyframes customTrackProgress {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

.chakra-stack,
.css-12ql7k8 {
  /* overflow-y: hidden !important; */
  width: 100% !important;
}
.chakra-stack::-webkit-scrollbar {
  display: none !important;
}

.plan_wraper {
  /* background-color: red; */
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: center;
  max-width: 60%;
  align-self: center;
  gap: 20px;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 48em) {
  .plan_wraper {
    max-width: 100%;
    /* background-color: red; */
  }
}
@media screen and (max-width: 55em) {
  .plan_wraper {
    max-width: 100%;
    /* background-color: green; */
  }
}
@media screen and (max-width: 1200px) {
  .plan_wraper {
    max-width: 100%;
    /* background-color: green; */
  }
}
