@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.body1 {
  font-family: "Quicksand", sans-serif;
  display: grid;
  place-items: center;
  /* height: 100vh; */
  /* background: #7f7fd5; */
  /* background: linear-gradient(to right, #91eae4, #86a8e7, #7f7fd5); */
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin-block: 2rem;
  gap: 2rem;
}

img {
  max-width: 100%;
  display: block;
  object-fit: cover;
}

.card {
  display: flex;
  flex-direction: column;
  width: clamp(20rem, calc(20rem + 2vw), 22rem);
  overflow: hidden;
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  background: #fff;
  /* background: linear-gradient(to right, #ffffff, #ece9e6);    */
}

.card__body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.tag {
  align-self: flex-start;
  padding: 0.25em 0.75em;
  border-radius: 1em;
  font-size: 0.75rem;
}

.tag + .tag {
  margin-left: 0.5em;
}

.tag-blue {
  background: #56ccf2;
  background: linear-gradient(to bottom, #2f80ed, #56ccf2);
  color: #fafafa;
}

.tag-brown {
  background: #d1913c;
  background: linear-gradient(to bottom, #ffd194, #d1913c);
  color: #fafafa;
}

.tag-red {
  background: #cb2d3e;
  background: linear-gradient(to bottom, #ef473a, #cb2d3e);
  color: #fafafa;
}

.card__body h4 {
  font-size: 1.5rem;
  text-transform: capitalize;
}

.card__footer {
  display: flex;
  padding: 1rem;
  margin-top: auto;
}

.user {
  display: flex;
  gap: 0.5rem;
}

.user__image {
  /* border-radius: 50%; */
  width: 320px;
  height: 200px;
  object-fit: fill;
}

.user__info > small {
  color: #666;
}

@media screen and (max-width: 500px) {
  .card {
    width: clamp(17rem, calc(17rem + 2vw), 22rem);
  }
  .body1 {
    height: 100% !important;
  }
  .card__body h4 {
    font-size: 1rem;
  }
  .card__body > p {
    font-size: 0.85rem;
  }
  .css-k008qs {
    flex-direction: column !important;
  }
}
