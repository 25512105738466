@keyframes colored-pulse {
  0% {
    -moz-box-shadow: 0 0 4px #bb1c19;
    -webkit-box-shadow: 0 0 4px #bb1c19;
    box-shadow: 0 0 4px #bb1c19;
  }

  40% {
    -moz-box-shadow: 0 0 15px #bb1c19;
    -webkit-box-shadow: 0 0 15px #bb1c19;
    box-shadow: 0 0 15px #bb1c19;
  }

  60% {
    -moz-box-shadow: 0 0 15px #bb1c19;
    -webkit-box-shadow: 0 0 15px #bb1c19;
    box-shadow: 0 0 15px #bb1c19;
  }

  100% {
    -moz-box-shadow: 0 0 4px #bb1c19;
    -webkit-box-shadow: 0 0 4px #bb1c19;
    box-shadow: 0 0 4px #bb1c19;
  }
}

@-moz-keyframes colored-pulse /* Firefox */ {
  0% {
    -moz-box-shadow: 0 0 4px #bb1c19;
    -webkit-box-shadow: 0 0 4px #bb1c19;
    box-shadow: 0 0 4px #bb1c19;
  }

  40% {
    -moz-box-shadow: 0 0 15px #bb1c19;
    -webkit-box-shadow: 0 0 15px #bb1c19;
    box-shadow: 0 0 15px #bb1c19;
  }

  60% {
    -moz-box-shadow: 0 0 15px #bb1c19;
    -webkit-box-shadow: 0 0 15px #bb1c19;
    box-shadow: 0 0 15px #bb1c19;
  }

  100% {
    -moz-box-shadow: 0 0 4px #bb1c19;
    -webkit-box-shadow: 0 0 4px #bb1c19;
    box-shadow: 0 0 4px #bb1c19;
  }
}

@-webkit-keyframes colored-pulse /* Safari and Chrome */ {
  0% {
    -moz-box-shadow: 0 0 4px #bb1c19;
    -webkit-box-shadow: 0 0 4px #bb1c19;
    box-shadow: 0 0 4px #bb1c19;
  }

  40% {
    -moz-box-shadow: 0 0 15px #bb1c19;
    -webkit-box-shadow: 0 0 15px #bb1c19;
    box-shadow: 0 0 15px #bb1c19;
  }

  60% {
    -moz-box-shadow: 0 0 15px #bb1c19;
    -webkit-box-shadow: 0 0 15px #bb1c19;
    box-shadow: 0 0 15px #bb1c19;
  }

  100% {
    -moz-box-shadow: 0 0 4px #bb1c19;
    -webkit-box-shadow: 0 0 4px #bb1c19;
    box-shadow: 0 0 4px #bb1c19;
  }
}

@-o-keyframes colored-pulse /* Opera */ {
  0% {
    -moz-box-shadow: 0 0 4px #bb1c19;
    -webkit-box-shadow: 0 0 4px #bb1c19;
    box-shadow: 0 0 4px #bb1c19;
  }

  40% {
    -moz-box-shadow: 0 0 15px #bb1c19;
    -webkit-box-shadow: 0 0 15px #bb1c19;
    box-shadow: 0 0 15px #bb1c19;
  }

  60% {
    -moz-box-shadow: 0 0 15px #bb1c19;
    -webkit-box-shadow: 0 0 15px #bb1c19;
    box-shadow: 0 0 15px #bb1c19;
  }

  100% {
    -moz-box-shadow: 0 0 4px #bb1c19;
    -webkit-box-shadow: 0 0 4px #bb1c19;
    box-shadow: 0 0 4px #bb1c19;
  }
}

.pulse-loader {
  background-color: #bb1c19;
  -moz-animation: colored-pulse 4s infinite;
  -webkit-animation: colored-pulse 4s infinite;
  -o-animation: colored-pulse 4s infinite;
  animation: colored-pulse 4s infinite;
}

#add_team_member_modal {
  width: 96%;
}

.ajax_form .ajax_form_submitting_indicator {
  display: none;
}

.ajax_form .ajax_form_success_indicator {
  display: none;
}

.ajax_form.ajax_form_submitting .ajax_form_submitting_indicator {
  display: inline-block;
}

.ajax_form.ajax_form_success .ajax_form_success_indicator {
  display: inline-block;
}

.ajax_form.ajax_form_success .hide_on_ajax_form_success {
  display: none;
}

.alert {
  border-radius: 8px;
  color: #090909;
  display: block;
  font-size: 14px;
  text-align: center;
  margin: 6px 0 6px;
  padding: 8px 24px;
  margin-left: auto;
  margin-right: auto;
}

.alert.error {
  background-color: #bb1c19;
  border: 1px solid #cc0000;
  color: #fff;
}

.alert.warning {
  background-color: #e28325;
  border: 1px solid #ef9910;
  color: #fff;
}

.alert.notice {
  background-color: #fdf5e0;
  border: 1px solid #f3e4c2;
}

.alert.success {
  background-color: #2cab49;
  border: 1px solid #b4dac3;
  color: #fefefe;
}

#docs_three_parts {
  display: flex;
}

@media screen and (max-width: 35.5em) {
  #docs_three_parts {
    display: block;
  }
}

#docs_side_column {
  background-color: #f9f5f0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 240px;
  overflow-y: auto;
  z-index: 1;
  padding-top: 66px;
  padding-bottom: 50px;
}

@media screen and (max-width: 35.5em) {
  #docs_side_column {
    position: relative;
    top: 0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

#docs_side_column .docs_nav_category {
  font-size: 18px;
  margin-bottom: 3px;
}

#docs_side_column .docs_nav_block {
  padding: 10px 20px;
}

#docs_side_column .docs_nav_link_container {
  font-size: 14px;
  margin: 10px 10px;
}

#docs_code_content {
  flex: 1;
  padding: 16px;
  padding-top: 64px;
  max-width: 33%;
  font-size: 14px;
}

@media screen and (max-width: 35.5em) {
  #docs_code_content {
    position: relative;
    margin-bottom: 20px;
    max-width: 98%;
    padding-top: 10px;
  }
}

#docs_main_content {
  margin-left: 240px;
  flex: 1;
  padding: 6px 40px 4px 10px;
  position: relative;
  font-size: 15px;
  line-height: 1.5;
  word-wrap: break-word;
}

@media screen and (max-width: 35.5em) {
  #docs_main_content {
    width: 100%;
    margin-left: 0;
    padding: 10px;
    box-sizing: border-box;
  }
}

#docs_main_content > *:first-child {
  margin-top: 0 !important;
}

#docs_main_content > *:last-child {
  margin-bottom: 0 !important;
}

#docs_main_content .anchor {
  display: inline-block;
  padding-right: 2px;
  margin-left: -18px;
}

#docs_main_content .anchor:focus {
  outline: none;
}

#docs_main_content h1,
#docs_main_content h2,
#docs_main_content h3,
#docs_main_content h4,
#docs_main_content h5,
#docs_main_content h6 {
  margin-top: 1em;
  margin-bottom: 16px;
  font-weight: bold;
  line-height: 1.4;
}

#docs_main_content h1:hover .anchor,
#docs_main_content h2:hover .anchor,
#docs_main_content h3:hover .anchor,
#docs_main_content h4:hover .anchor,
#docs_main_content h5:hover .anchor,
#docs_main_content h6:hover .anchor {
  text-decoration: none;
}

#docs_main_content h1 tt,
#docs_main_content h1 code,
#docs_main_content h2 tt,
#docs_main_content h2 code,
#docs_main_content h3 tt,
#docs_main_content h3 code,
#docs_main_content h4 tt,
#docs_main_content h4 code,
#docs_main_content h5 tt,
#docs_main_content h5 code,
#docs_main_content h6 tt,
#docs_main_content h6 code {
  font-size: inherit;
}

#docs_main_content h1 {
  border-bottom: 1px solid #e2e2e2;
  font-size: 2.25em;
  line-height: 1.2;
  padding-bottom: 0.1em;
}

#docs_main_content h1.anchor {
  line-height: 1;
}

#docs_main_content h2 {
  border-bottom: 1px solid #e2e2e2;
  font-size: 1.75em;
  line-height: 1.225;
}

#docs_main_content h2.anchor {
  line-height: 1;
}

#docs_main_content h3 {
  font-size: 1.5em;
  line-height: 1.43;
}

#docs_main_content h3.anchor {
  line-height: 1.2;
}

#docs_main_content h4 {
  font-size: 1.25em;
}

#docs_main_content h4.anchor {
  line-height: 1.2;
}

#docs_main_content h5 {
  font-size: 1em;
}

#docs_main_content h5.anchor {
  line-height: 1.1;
}

#docs_main_content h6 {
  font-size: 1em;
  color: #555;
}

#docs_main_content h6.anchor {
  line-height: 1.1;
}

#docs_main_content p,
#docs_main_content blockquote,
#docs_main_content ul,
#docs_main_content ol,
#docs_main_content dl,
#docs_main_content table {
  margin-top: 0;
  margin-bottom: 16px;
}

#docs_main_content hr {
  height: 4px;
  padding: 0;
  margin: 16px 0;
  background-color: #e7e7e7;
  border: 0 none;
}

#docs_main_content ul,
#docs_main_content ol {
  padding-left: 2em;
}

#docs_main_content ul.no-list,
#docs_main_content ol.no-list {
  padding: 0;
  list-style-type: none;
}

#docs_main_content ul ul,
#docs_main_content ul ol,
#docs_main_content ol ol,
#docs_main_content ol ul {
  margin-top: 0;
  margin-bottom: 0;
}

#docs_main_content li > p {
  margin-top: 16px;
}

#docs_main_content dl {
  padding: 0;
}

#docs_main_content dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: bold;
}

#docs_main_content dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}

#docs_main_content blockquote {
  padding: 0 15px;
  color: #777;
  border-left: 4px solid #ddd;
}

#docs_main_content blockquote > :first-child {
  margin-top: 0;
}

#docs_main_content blockquote > :last-child {
  margin-bottom: 0;
}

#docs_main_content table {
  display: block;
  width: 100%;
  overflow: auto;
  word-break: normal;
  word-break: keep-all;
}

#docs_main_content table th {
  font-weight: bold;
}

#docs_main_content table th,
#docs_main_content table td {
  padding: 6px 13px;
  border: 1px solid #ddd;
}

#docs_main_content table tr {
  background-color: #fff;
  border-top: 1px solid #ccc;
}

#docs_main_content table tr:nth-child(2n) {
  background-color: #f4f6f4;
}

#docs_main_content img {
  max-width: 100%;
  box-sizing: border-box;
  background-color: #fff;
}

#docs_main_content img[align="right"] {
  padding-left: 20px;
}

#docs_main_content img[align="left"] {
  padding-right: 20px;
}

#docs_main_content .emoji {
  max-width: none;
}

#docs_main_content span.frame {
  display: block;
  overflow: hidden;
}

#docs_main_content span.frame > span {
  display: block;
  float: left;
  width: auto;
  padding: 7px;
  margin: 13px 0 0;
  overflow: hidden;
  border: 1px solid #ddd;
}

#docs_main_content span.frame span img {
  display: block;
  float: left;
}

#docs_main_content span.frame span span {
  display: block;
  padding: 5px 0 0;
  clear: both;
  color: #090909;
}

#docs_main_content span.align-center {
  display: block;
  overflow: hidden;
  clear: both;
}

#docs_main_content span.align-center > span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: center;
}

#docs_main_content span.align-center span img {
  margin: 0 auto;
  text-align: center;
}

#docs_main_content span.align-right {
  display: block;
  overflow: hidden;
  clear: both;
}

#docs_main_content span.align-right > span {
  display: block;
  margin: 13px 0 0;
  overflow: hidden;
  text-align: right;
}

#docs_main_content span.align-right span img {
  margin: 0;
  text-align: right;
}

#docs_main_content span.float-left {
  display: block;
  float: left;
  margin-right: 13px;
  overflow: hidden;
}

#docs_main_content span.float-left span {
  margin: 13px 0 0;
}

#docs_main_content span.float-right {
  display: block;
  float: right;
  margin-left: 13px;
  overflow: hidden;
}

#docs_main_content span.float-right > span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: right;
}

#api_keys_keys_page #your_keys_header {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  display: inline-block;
}

#api_keys_keys_page .api-key-create-button {
  float: right;
  margin-top: 12px;
}

#api_keys_keys_page #api_keys_list_container .api-key-row-name {
  flex: 2;
}

#api_keys_keys_page #api_keys_list_container .api-key-row-created-date {
  flex: 2;
}

#api_keys_keys_page #api_keys_list_container .api-key-row-secret {
  flex: 3;
}
.signed-in-link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.auth-form {
  margin-left: auto;
  margin-right: auto;
  width: 375px;
}

@media screen and (max-width: 48em) {
  .auth-form {
    width: auto;
  }
}

.auth-form input {
  width: 100%;
}

.auth-form .auth-form-secondary-info {
  font-size: 11px;
  text-align: left;
  line-height: 12px;
  padding-top: 4px;
}

.auth-form div.form-row {
  margin: 10px 0;
}

.auth-form div.form-row label {
  display: inline-block;
  margin-top: 8px;
}

.semiblank-after-form-title {
  font-size: 11px;
  color: #555;
  margin-top: 14px;
  text-align: center;
}

@media screen and (max-width: 48em) {
  .semiblank-after-form-title {
    font-size: 9px;
  }
}

#blog_show_page p {
  line-height: 1.4;
  margin-top: 12px;
  margin-bottom: 12px;
}

#blog_main_content p {
  line-height: 1.4;
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 14px;
}

.blog-title {
  margin: 15px 0 7px;
}

.blog-date {
  font-style: italic;
  font-size: 12px;
  margin: 15px 0;
}

.blog-page-teaser h3 {
  margin-bottom: 5px;
}

blockquote.quote {
  background: #f8f8f8;
  border-left: 10px solid #ccc;
  font-family: Georgia, serif;
  font-size: 18px;
  line-height: 1.4;
  font-style: italic;
  color: #222;
  margin: 1em 0;
  padding: 0.5em 10px;
}

blockquote.quote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote cite {
  color: #888;
  font-size: 14px;
  display: block;
  margin-top: 5px;
}

blockquote cite:before {
  content: "\2014 \2009";
}

.button,
a.button {
  border: none;
  border-radius: 16px;
  background-color: #044ea0;
  cursor: pointer;
  color: #fff;
  display: inline-block;
  font-weight: 400 !important;
  font-size: 14px;
  text-transform: uppercase;
  padding: 12px 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.button:hover,
a.button:hover {
  background-color: #055ab9;
}

.button:active,
a.button:active {
  background-color: #034287;
}

.button:disabled,
.button.disabled,
a.button:disabled,
a.button.disabled {
  background-color: #777;
  cursor: default;
}

.button.white,
a.button.white {
  background-color: #fefefe;
  color: #044ea0;
  border: 1px solid #bbbbbb;
}

.button.white:hover,
a.button.white:hover {
  background-color: #f1f1f1;
  text-decoration: none;
}

.button.white:active,
a.button.white:active {
  background-color: white;
  text-decoration: none;
}

.button.orange,
a.button.orange {
  background-color: #e28325;
  color: #fff;
}

.button.orange:hover,
a.button.orange:hover {
  background-color: #e5903c;
  text-decoration: none;
}

.button.orange:active,
a.button.orange:active {
  background-color: #d2761c;
}

.button.green,
a.button.green {
  background-color: #2cab49;
}

.button.green:hover,
a.button.green:hover {
  background-color: #31bf52;
  color: #fff;
  text-decoration: none;
}

.button.green:active,
a.button.green:active {
  background-color: #279740;
}

.button.green:disabled,
.button.green.disabled,
a.button.green:disabled,
a.button.green.disabled {
  background-color: #777;
  cursor: default;
}

.button.yellow,
a.button.yellow {
  color: #090909;
  background-color: #ffd210;
}

.button.yellow:hover,
a.button.yellow:hover {
  background-color: #ffd72a;
}

.button.yellow:active,
a.button.yellow:active {
  background-color: #f6c700;
}

#downgrade_plan_modal #downgrade_plan_modal_description,
#downgrade_plan_modal #downgrade_to_free_description,
#downgrade_plan_modal #downgrade_prorated_description {
  font-size: 14px;
  margin: 5px 0 12px;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
  position: relative;
}

.chat-container.no-page-header {
  height: 100vh;
}

.chat-header {
  background-color: #333639;
  color: #fefefe;
  height: 56px;
}

.chat-header .chat-header-title {
  display: inline-block;
  font-size: 14px;
  vertical-align: center;
  box-sizing: border-box;
  padding-left: 20px;
  padding-top: 18px;
  height: 56px;
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-header .chat-header-buttons {
  float: right;
  margin-right: 10px;
  padding-top: 7px;
  padding-right: 9px;
}

.chat-header .chat-header-buttons .fa,
.chat-header .chat-header-buttons .fa-solid,
.chat-header .chat-header-buttons .fa-regular,
.chat-header .chat-header-buttons .fa-light,
.chat-header .chat-header-buttons .fa-brands {
  font-size: 26px;
  color: #fefefe;
}

.chat-header-button {
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  margin-left: 3px;
}

.chat-header-button img {
  margin-top: 10px;
}

.chat-header-button:hover {
  background-color: #454649;
}

.chat-header-button.active {
  background-color: #555659;
}

.chat-document-container {
  flex: 1;
}

.chat-footer-fix {
  background-color: #333639;
  height: 1%;
}

.chat-main-content-container {
  flex: 1;
  overflow-y: auto;
}

.chat-claims-container {
  padding: 10px;
}

.chat-claims-container input,
.chat-claims-container textarea {
  max-width: 96%;
}

.chat-claims-container .query-feedback-container {
  float: left;
}

.chat-claims-container .chat-claims-error-message {
  color: #bb1c19;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  margin-top: 20px;
}

.chat-claims-container .chat-claims-output {
  border: 1px solid #eee;
  box-shadow: 0 0 3px #eee;
  padding: 0 5px 5px;
  margin-top: 8px;
  margin-bottom: 2px;
}

.chat-claims-container .chat-claims-output-html p:first-child {
  margin-top: 10px;
}

.chat-claims-container .chat-claims-output-html p:not(:last-child),
.chat-claims-container .chat-claims-output-html table:not(:last-child),
.chat-claims-container .chat-claims-output-html h1:not(:last-child),
.chat-claims-container .chat-claims-output-html h2:not(:last-child),
.chat-claims-container .chat-claims-output-html h3:not(:last-child),
.chat-claims-container .chat-claims-output-html h4:not(:last-child),
.chat-claims-container .chat-claims-output-html h5:not(:last-child),
.chat-claims-container .chat-claims-output-html h6:not(:last-child) {
  margin-bottom: 20px;
}

.chat-claims-container .chat-claims-output-html h1,
.chat-claims-container .chat-claims-output-html h2,
.chat-claims-container .chat-claims-output-html h3,
.chat-claims-container .chat-claims-output-html h4,
.chat-claims-container .chat-claims-output-html h5,
.chat-claims-container .chat-claims-output-html h6 {
  font-size: 100% !important;
}

.chat-claims-container .chat-claims-output-html p,
.chat-claims-container .chat-claims-output-html code {
  white-space: pre-wrap;
}

.chat-claims-container .chat-claims-output-html ul,
.chat-claims-container .chat-claims-output-html ol {
  list-style-position: inside;
}

.chat-claims-container .chat-claims-output-html table {
  border-collapse: collapse;
  border: 1px solid #d0d0d0 !important;
  width: 100%;
}

.chat-claims-container .chat-claims-output-html table th,
.chat-claims-container .chat-claims-output-html table td {
  border: 1px solid #d0d0d0 !important;
  padding: 6px !important;
  text-align: left;
}

.chat-claims-generating {
  text-align: center;
}

.chat-message {
  font-size: 14px;
  max-width: 80%;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  display: inline-block;
  position: relative;
}

.chat-message.chat-message-from-bot {
  background-color: #f1f1f1;
  color: #333;
  float: left;
}

.chat-message.chat-message-from-bot::before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #f1f1f1;
  left: -7px;
  top: 7px;
}

.chat-message.chat-message-from-user {
  background-color: #00aaff;
  color: #fff;
  float: right;
}

.chat-message.chat-message-from-user::before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #00aaff;
  right: -7px;
  bottom: 7px;
}

.chat-message-text,
.chat-history-item-response {
  overflow-x: auto;
}

.chat-message-text p:not(:last-child),
.chat-message-text table:not(:last-child),
.chat-message-text h1:not(:last-child),
.chat-message-text h2:not(:last-child),
.chat-message-text h3:not(:last-child),
.chat-message-text h4:not(:last-child),
.chat-message-text h5:not(:last-child),
.chat-message-text h6:not(:last-child),
.chat-history-item-response p:not(:last-child),
.chat-history-item-response table:not(:last-child),
.chat-history-item-response h1:not(:last-child),
.chat-history-item-response h2:not(:last-child),
.chat-history-item-response h3:not(:last-child),
.chat-history-item-response h4:not(:last-child),
.chat-history-item-response h5:not(:last-child),
.chat-history-item-response h6:not(:last-child) {
  margin-bottom: 20px;
}

.chat-message-text h1,
.chat-message-text h2,
.chat-message-text h3,
.chat-message-text h4,
.chat-message-text h5,
.chat-message-text h6,
.chat-history-item-response h1,
.chat-history-item-response h2,
.chat-history-item-response h3,
.chat-history-item-response h4,
.chat-history-item-response h5,
.chat-history-item-response h6 {
  font-size: 100% !important;
}

.chat-message-text p,
.chat-message-text code,
.chat-history-item-response p,
.chat-history-item-response code {
  white-space: pre-wrap;
}

.chat-message-text ul,
.chat-message-text ol,
.chat-history-item-response ul,
.chat-history-item-response ol {
  list-style-position: inside;
}

.chat-message-text table,
.chat-history-item-response table {
  background-color: #fefefe;
  border-collapse: collapse;
  border: 1px solid #d0d0d0 !important;
  width: 100%;
}

.chat-message-text table th,
.chat-message-text table td,
.chat-history-item-response table th,
.chat-history-item-response table td {
  border: 1px solid #d0d0d0 !important;
  padding: 6px !important;
  text-align: left;
}

.chat-message-text table th,
.chat-history-item-response table th {
  font-weight: bold;
  background-color: #e0e0e0;
}

.chat-message-text table tr:nth-child(2n),
.chat-history-item-response table tr:nth-child(2n) {
  background-color: #f4f5f4;
}

.chat-message-actions {
  color: #888;
  display: block;
  margin-top: 4px;
}

.chat-message-actions a.chat-message-action-copy {
  color: #888;
  display: inline-block;
  float: right;
  margin-right: 3px;
}

.chat-message-actions a.chat-message-action-copy:hover {
  color: #044ea0;
}

.chat-message-actions a.chat-message-action-copy:active {
  color: #044ea0;
}

.chat-message-actions a.chat-message-action-copy .fa-check,
.chat-message-actions a.chat-message-action-copy .fa-check:hover,
.chat-message-actions a.chat-message-action-copy .fa-check:active {
  color: #111 !important;
}

.chat-history-item-response {
  max-height: 200px;
  overflow-y: hidden;
}

.chat-send-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
}

.chat-send-input-container #chat_input {
  box-sizing: border-box;
  flex: 1;
  height: 40px;
  padding: 10px 10px;
  resize: none;
  overflow: hidden;
}

.chat-send-input-container .button.green {
  height: 40px;
  width: 100px;
  margin-left: 10px;
}

.chat-history-empty {
  text-align: center;
  font-size: 13px;
  padding: 10px;
}

.chat-history-item {
  padding: 10px;
  border-bottom: 1px solid #d0d0d0;
  cursor: pointer;
}

.chat-history-item:hover {
  background-color: #f2f2f2;
}

.chat-history-item .chat-history-item-date {
  font-size: 11px;
  color: #999;
}

.chat-history-item .chat-history-item-text {
  font-weight: 600;
}

.chat-saved-prompts-container .chat-saved-prompts-header {
  font-weight: 600;
  text-align: center;
  margin: 10px;
  font-size: 16px;
}

.chat-saved-prompts-container .chat-saved-prompt-item {
  padding: 10px;
  border-bottom: 1px solid #d0d0d0;
  cursor: pointer;
  position: relative;
  display: block;
}

.chat-saved-prompts-container .chat-saved-prompt-item:first-child {
  border-top: 1px solid #d0d0d0;
}

.chat-saved-prompts-container .chat-saved-prompt-item:hover {
  background-color: #f2f2f2;
}

.chat-saved-prompts-container .chat-saved-prompt-item-prompt-action {
  font-size: 14px;
  color: #555;
  position: absolute;
  top: 10px;
}

.chat-saved-prompts-container .chat-saved-prompt-item-prompt-action-edit {
  right: 30px;
}

.chat-saved-prompts-container .chat-saved-prompt-item-prompt-action-edit:hover {
  color: #2cab49;
}

.chat-saved-prompts-container .chat-saved-prompt-item-prompt-action-delete {
  right: 10px;
}

.chat-saved-prompts-container
  .chat-saved-prompt-item-prompt-action-delete:hover {
  color: #bb1c19;
}

code {
  font-family: monospace;
  background-color: #eee;
  padding-left: 2px;
  padding-right: 2px;
}

pre > code {
  padding: 0;
}

.CodeMirror {
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: monospace;
  position: relative;
  overflow: hidden;
  background-color: #eee;
  color: #000;
}

.CodeMirror-lines {
  padding: 4px 0;
}

.CodeMirror pre {
  padding: 0 4px;
}

.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
  background-color: white;
}

.CodeMirror-gutters {
  border-right: 1px solid #ccc;
  background-color: #eee;
  white-space: nowrap;
}

.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap;
}

.CodeMirror-guttermarker {
  color: black;
}

.CodeMirror-guttermarker-subtle {
  color: #999;
}

.CodeMirror-cursor {
  border-left: 1px solid black;
  border-right: none;
  width: 0;
}

.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}

.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0;
  background: #7e7;
}

.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}

.cm-animate-fat-cursor {
  width: auto;
  border: 0;
  -webkit-animation: blink 1.06s steps(1) infinite;
  -moz-animation: blink 1.06s steps(1) infinite;
  animation: blink 1.06s steps(1) infinite;
  background-color: #7e7;
}

@-moz-keyframes blink {
  0% {
  }

  50% {
    background-color: transparent;
  }

  100% {
  }
}

@-webkit-keyframes blink {
  0% {
  }

  50% {
    background-color: transparent;
  }

  100% {
  }
}

@keyframes blink {
  0% {
  }

  50% {
    background-color: transparent;
  }

  100% {
  }
}

.cm-tab {
  display: inline-block;
  text-decoration: inherit;
}

.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  position: absolute;
}

div.CodeMirror-selected {
  background: #e0e0e0;
}

.CodeMirror-line::selection,
.CodeMirror-line > span::selection,
.CodeMirror-line > span > span::selection {
  background: #e0e0e0;
}

.CodeMirror-line::-moz-selection,
.CodeMirror-line > span::-moz-selection,
.CodeMirror-line > span > span::-moz-selection {
  background: #e0e0e0;
}

.CodeMirror-gutters {
  background: #f5f5f5;
  border-right: 0px;
}

.CodeMirror-guttermarker {
  color: #ac4142;
}

.CodeMirror-guttermarker-subtle {
  color: #b0b0b0;
}

.CodeMirror-linenumber {
  color: #b0b0b0;
}

.CodeMirror-cursor {
  border-left: 1px solid #505050;
}

span.cm-comment {
  color: #777;
}

span.cm-atom {
  color: #800;
}

span.cm-number {
  color: #ea7555;
}

span.cm-property,
span.cm-attribute {
  color: #308909;
}

span.cm-keyword {
  color: #702122;
}

span.cm-string {
  color: #151;
}

span.cm-variable {
  color: #222;
}

span.cm-variable-2 {
  color: #6a9fe5;
}

span.cm-def {
  color: #03a;
}

span.cm-bracket {
  color: #202020;
}

span.cm-tag {
  color: #7b248d;
}

span.cm-link {
  color: #aa759f;
}

span.cm-error {
  background: #ac4142;
  color: #505050;
}

.CodeMirror-activeline-background {
  background: #dddcdc;
}

.CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}

div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0a0;
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #e11;
}

.CodeMirror-matchingtag {
  background: rgba(255, 150, 0, 0.3);
}

.CodeMirror-activeline-background {
  background: #e8f2ff;
}

.CodeMirror-scroll {
  overflow: scroll !important;
  margin-bottom: -30px;
  margin-right: -30px;
  padding-bottom: 30px;
  height: 100%;
  outline: none;
  position: relative;
}

.CodeMirror-sizer {
  position: relative;
  border-right: 30px solid transparent;
}

.CodeMirror-vscrollbar,
.CodeMirror-hscrollbar,
.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none;
}

.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}

.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0;
}

.CodeMirror-gutter-filler {
  left: 0;
  bottom: 0;
}

.CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
}

.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  margin-bottom: -30px;
  zoom: 1;
  display: inline;
}

.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: none !important;
  border: none !important;
}

.CodeMirror-gutter-background {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4;
}

.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4;
}

.CodeMirror-gutter-wrapper {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.CodeMirror-lines {
  cursor: text;
  min-height: 1px;
}

.CodeMirror pre {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
}

.CodeMirror-wrap pre {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  overflow: auto;
}

.CodeMirror-code {
  outline: none;
}

.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.CodeMirror-cursor {
  position: absolute;
}

.CodeMirror-measure pre {
  position: static;
}

div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3;
}

div.CodeMirror-dragcursors {
  visibility: visible;
}

.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected {
  background: #d9d9d9;
}

.CodeMirror-focused .CodeMirror-selected {
  background: #cbebff;
}

.CodeMirror-crosshair {
  cursor: crosshair;
}

.CodeMirror-line::selection,
.CodeMirror-line > span::selection,
.CodeMirror-line > span > span::selection {
  background: #cbebff;
}

.CodeMirror-line::-moz-selection,
.CodeMirror-line > span::-moz-selection,
.CodeMirror-line > span > span::-moz-selection {
  background: #cbebff;
}

.cm-searching {
  background: #ffa;
  background: rgba(255, 255, 0, 0.4);
}

.CodeMirror span {
  vertical-align: text-bottom;
}

.cm-force-border {
  padding-right: 0.1px;
}

@media print {
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}

.cm-tab-wrap-hack:after {
  content: "";
}

span.CodeMirror-selectedtext {
  background: none;
}

.credits-bar {
  border-radius: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border: 0;
  padding: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: right;
  color: #090909;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: auto;
}

.credits-bar .credits-bar-current {
  background-color: #f1df67;
  display: inline-block;
  text-align: center;
  float: left;
  height: 100%;
  border-top-left-radius: 5px;
}

.credits-bar .credits-bar-text {
  display: inline-block;
  text-align: center;
  color: #1c3361;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 11px;
  padding: 5px 6px;
}

.custom-pdf-viewer {
  display: block;
  position: relative;
}

.custom-pdf-viewer
  input.custom-pdf-input:not([type="submit"]):not([type="checkbox"]) {
  width: 50px;
}

.custom-pdf-middle-separator {
  margin-right: 6px;
  margin-left: 12px;
  font-size: 12px;
}

.custom-pdf-header {
  background-color: #323639;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 100%;
  z-index: 1030;
}

.custom-pdf-progress {
  position: absolute;
  top: 56px;
  right: 0;
  left: 0;
  height: 3px;
}

.custom-pdf-progress .custom-pdf-progress-bar {
  height: 100%;
  background-color: #0093e6;
}

.custom-pdf-title {
  color: #fff;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.custom-pdf-title select.custom-pdf-title-select {
  background-color: #555555;
  color: #fff;
  border: none;
  padding: 8px 30px 8px 12px;
  border-radius: 18px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" fill="white"><path d="M0 0h10L5 5 0 0z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  max-width: 100%;
}

.custom-pdf-title select.custom-pdf-title-select:focus {
  border-color: #323639;
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}

.custom-pdf-title select.custom-pdf-title-select option {
  background-color: #555555;
  border-radius: 18px;
  color: white;
  padding: 8px 12px;
  font-size: 13px;
}

.custom-pdf-left-buttons {
  min-width: 60px;
}

.custom-pdf-header .custom-pdf-toolbar {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex: 1 1 0;
  color: #ffffff;
  gap: 10px;
}

.custom-pdf-header .custom-pdf-right-buttons {
  display: flex;
  text-align: right;
}

.custom-pdf-container {
  background-color: #323639;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 112px);
  overflow-y: hidden;
}

.custom-pdf-container.embedded {
  height: calc(100vh - 56px);
}

.custom-pdf-container.embedded-beneath-chat {
  height: calc(100vh - 112px);
}

.custom-pdf-container .custom-pdf-pages {
  order: 2;
}

.custom-pdf-container .custom-pdf-thumbnails {
  order: 1;
  padding-top: 10px;
  background-color: #323639;
  width: 300px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
}

.custom-pdf-thumbnails .react-pdf__Document,
.custom-pdf-pages .react-pdf__Document {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.custom-pdf-container .custom-pdf-thumbnails .thumbnail {
  opacity: 0.85;
}

.custom-pdf-container .custom-pdf-thumbnails .thumbnail.active {
  opacity: 1;
}

.custom-pdf-container .custom-pdf-thumbnails .thumbnail .thumbnail-page {
  border: 4px solid transparent;
  background: transparent !important;
}

.custom-pdf-container .custom-pdf-thumbnails .thumbnail.active .thumbnail-page {
  border: 4px solid lightskyblue;
}

.custom-pdf-container .custom-pdf-thumbnails > * {
  margin: 0 auto;
}

.custom-pdf-container .custom-pdf-pages {
  flex: 1 1 0;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
}

.custom-pdf-container .custom-pdf-pages > * {
  margin: 0 auto;
}

.custom-pdf-page-number {
  padding-top: 3px;
  text-align: center;
  color: #fff;
  font-size: 12px;
}

.custom-pdf-page-zoom,
.custom-pdf-input-number {
  display: flex;
  gap: 3px;
  line-height: 31px;
  vertical-align: middle;
}

.custom-pdf-toggle-thumbnails-button {
  margin: 0 10px;
}

.custom-pdf-toggle-thumbnails-button img {
  top: 3px;
  position: relative;
}

.custom-pdf-page-zoom input:not([type="submit"]):not([type="checkbox"]),
.custom-pdf-input-number input:not([type="submit"]):not([type="checkbox"]) {
  width: 30px;
  text-align: center;
  color: #fff;
  background-color: #555555;
  border: 0;
}

.custom-pdf-page-zoom {
  gap: 5px;
}

.custom-pdf-page-zoom input {
  width: 50px;
}

.custom-pdf-zoom-button {
  border: 0;
  background: none;
  box-shadow: none;
  border-radius: 50%;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}

.custom-pdf-page-zoom > div {
  display: flex;
  align-items: center;
}

#delete_api_key_modal input[name="confirmation"] {
  width: 96%;
}

#delete_file_or_directory_modal .contents {
  margin-top: 10px;
}

#delete_file_or_directory_modal .contents p {
  font-size: 14px;
}

#delete_file_or_directory_modal .modal-buttons {
  margin-top: 10px;
}

/* html,body {
    height: 100%
} */

/* body {
    background-color: #FFF;
    color: #333;
    font-family: "Inter", "Lucida Grande", "Lucida Sans", Geneva, Verdana, sans-serif;
    font-size: 12px;
    line-height: 20px;
    margin: 0;
    overflow-x: auto;
    padding: 0;
    position: relative
} */

#whole-np {
  font-family: "Inter", "Lucida Grande", "Lucida Sans", Geneva, Verdana,
    sans-serif;
  min-height: 100%;
  position: relative;
}

a {
  color: #044ea0;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: #e28325;
  text-decoration: underline;
}

a:active {
  color: #bb1c19;
}

a img {
  border: 0;
}

.fixed-width {
  padding-left: 9px;
  padding-right: 9px;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}

#content_outer {
  padding-bottom: 10px;
  width: 66%;
}

#content_inner {
  padding: 10px 20px;
}

.page-title {
  margin: 24px;
  text-align: center;
}

.page-subtitle {
  font-size: 14px;
  font-weight: normal;
  text-align: center;
}

.flash {
  color: #000;
  background-color: #ccc;
  padding: 5px 0px;
  text-align: center;
}

.flash.success {
  background-color: #6d6;
}

.flash.error {
  background-color: #d00;
  color: #fff;
  font-weight: bold;
}

/* input[type='text'],input[type='password'] {
    border: solid 1px #E3E3E3;
    color: #1964A3;
    display: inline-block;
    font-size: 13px;
    height: 16px;
    line-height: 16px;
    padding: 5px;
    width: 95%
}

input[type='text']:disabled,input[type='password']:disabled {
    color: #999
} */

/* textarea {
    border: solid 1px #E3E3E3;
    color: #1964A3;
    font-size: 13px;
    line-height: 16px;
    padding: 5px;
    width: 95%
}

textarea:disabled {
    color: #999
} */
/* 
select {
    border: solid 1px #E3E3E3;
    color: #1964A3;
    font-size: 12px
}

select:disabled {
    color: #999
} */

/* td {
    padding-right: 4px;
    padding-left: 4px;
    padding-top: 2px;
    padding-bottom: 2px
} */

.no-mobile-select-highlight {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.no-mobile-select-highlight:focus {
  outline: none !important;
}

.dropzone,
.dropzone * {
  box-sizing: border-box;
}

.dropzone {
  border: 2px dashed #044ea0;
  border-radius: 5px;
  background: white;
  min-height: 150px;
  min-width: 250px;
  width: 50%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding: 34px 54px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dropzone_upload_icon {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.dropzone.dz-clickable {
  cursor: pointer;
}

.dropzone.dz-clickable * {
  cursor: default;
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  cursor: pointer;
}

.dropzone.dz-started .dz-message {
  display: none;
}

.dropzone.dz-drag-hover {
  border-style: solid;
}

.dropzone.dz-drag-hover .dz-message {
  opacity: 0.5;
}

.dropzone .dz-message {
  text-align: center;
  margin: 2em 0;
  font-weight: 400;
}

.dropzone .dz-message .note {
  font-size: 0.8em;
  font-weight: 200;
  display: block;
  margin-top: 1.4rem;
}

.dropzone .dz-message .dz-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.dropzone .dz-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-top: 16px;
  margin-bottom: 16px;
  min-height: 100px;
}

@media screen and (min-width: 770px) {
  .dropzone .dz-preview {
    margin-left: 25px;
  }
}

.dropzone .dz-preview:hover {
  z-index: 1000;
}

.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 20px;
  background: #999;
  background: linear-gradient(to bottom, #eee, #ddd);
}

.dropzone .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}

.dropzone .dz-preview.dz-image-preview {
  background: white;
}

.dropzone .dz-preview.dz-image-preview .dz-details {
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.dropzone .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
}

.dropzone .dz-preview .dz-remove:hover {
  text-decoration: underline;
}

.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone .dz-preview .dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
}

.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}

.dropzone .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
  border: 1px solid rgba(200, 200, 200, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid transparent;
}

.dropzone .dz-preview .dz-details .dz-filename span,
.dropzone .dz-preview .dz-details .dz-size span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}

.dropzone .dz-preview:hover .dz-image img {
  -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-filter: blur(8px);
  filter: blur(8px);
}

.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
}

.dropzone .dz-preview .dz-image img {
  display: block;
}

.dropzone .dz-preview.dz-success .dz-success-mark {
  -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -27px;
}

.dropzone .dz-preview .dz-success-mark svg,
.dropzone .dz-preview .dz-error-mark svg {
  display: block;
  width: 54px;
  height: 54px;
}

.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in;
  -moz-transition: opacity 0.4s ease-in;
  -ms-transition: opacity 0.4s ease-in;
  -o-transition: opacity 0.4s ease-in;
  transition: opacity 0.4s ease-in;
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse 6s ease infinite;
  -moz-animation: pulse 6s ease infinite;
  -ms-animation: pulse 6s ease infinite;
  -o-animation: pulse 6s ease infinite;
  animation: pulse 6s ease infinite;
}

.dropzone .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  width: 80px;
  margin-left: -40px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-transform: scale(1);
  border-radius: 8px;
  overflow: hidden;
}

.dropzone .dz-preview .dz-progress .dz-upload {
  background: #333;
  background: linear-gradient(to bottom, #666, #444);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  -webkit-transition: width 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out;
  -ms-transition: width 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out;
  transition: width 300ms ease-in-out;
}

.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}

.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  display: block;
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  font-size: 13px;
  top: 130px;
  left: -10px;
  width: 140px;
  background: #be2626;
  background: linear-gradient(to bottom, #be2626, #a92222);
  padding: 0.5em 1.2em;
  color: white;
}

.dropzone .dz-preview .dz-error-message:after {
  content: "";
  position: absolute;
  top: -6px;
  left: 64px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #be2626;
}

.embed-code {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 6px;
  margin: 4px 0;
  overflow: hidden;
}

.error-modal-message {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  text-align: center;
}

#static_faq_page #top_area {
  /* background-image: url(/assets/fingerbg_compressed-1eb5473617640b34998d0515faf9d533ade584e4d2329a0bf4e919e3d5bb5a1e.jpg); */
  background-size: cover;
  background-position-y: 50%;
  color: #fff;
  min-height: 130px;
  padding: 100px 0 35px;
  position: relative;
  text-align: center;
}

@media screen and (max-width: 35.5em) {
  #static_faq_page #top_area {
    padding: 70px 0 0px;
  }
}

#static_faq_page #top_area h1 {
  font-size: 32px;
}

@media screen and (max-width: 48em) {
  #static_faq_page #top_area h1 {
    font-size: 24px;
  }
}

#static_faq_page #faq_search_container {
  position: absolute;
  bottom: -17px;
  width: 99%;
}

#static_faq_page #faq_search {
  padding: 10px 15px;
}

@media screen and (max-width: 48em) {
  #static_faq_page #faq_search {
    max-width: 94%;
  }
}

#faq_answers_area {
  padding: 30px 0 25px;
}

.faq-question {
  margin: 10px 0;
  font-size: 20px;
}

.faq-answer {
  text-align: justify;
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 15px;
}

.faq-answer p {
  margin-bottom: 12px;
}

#file_info_modal.modal {
  min-width: 200px;
  max-width: 600px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  left: 50%;
  top: 100px;
  transform: translate(-50%, 0);
}

#file_info_modal.modal .contents {
  max-height: 68vh;
  overflow-y: auto;
}

#file_info_modal_table {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  margin-bottom: 5px;
}

.file-page-menu {
  max-width: 300px;
  z-index: 8000;
  border: 1px solid #ccc;
  border-bottom: 0px;
  right: 22px;
  top: 50px;
  position: absolute;
}

.file-page-menu .file-page-menu-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#d9d9d9)
  );
  background: -moz-linear-gradient(top, #fff, #d9d9d9);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF', endColorstr='#D9D9D9');
  border-bottom: 1px solid #ccc;
  display: block;
  position: relative;
  padding: 5px 5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  width: 120px;
}

.file-page-menu .file-page-menu-item:hover {
  background-color: #0093e6;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#0093e6),
    to(#044ea0)
  );
  background: -moz-linear-gradient(top, #0093e6, #044ea0);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0093E6', endColorstr='#044EA0');
  color: #fff;
}

.file-page-menu .file-page-menu-item:active {
  color: #4dbfff;
  background-color: #eee;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#eee),
    to(silver)
  );
  background: -moz-linear-gradient(top, #eee, silver);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EEE', endColorstr='#C0C0C0');
}

.file-page-menu .file-page-menu-item.disabled,
.file-page-menu .file-page-menu-item.disabled:hover,
.file-page-menu .file-page-menu-item.disabled:active {
  background-color: #d0d0d0;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#d0d0d0),
    to(#aaa)
  );
  background: -moz-linear-gradient(top, #d0d0d0, #aaa);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D0D0D0', endColorstr='#AAA');
  border-color: #aaa;
  color: #555;
  cursor: default;
}

#footer {
  background-color: #044ea0;
  border-top: 1px solid #666;
  height: 112px;
  padding: 0px 20px;
  /* position: absolute; */
  margin: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 800;
}

#footer > div {
  color: #fff;
  font-size: 11px;
  margin: 0 auto;
  padding: 15px 0 5px;
}

#footer > div p {
  font-size: 11px;
  line-height: 1;
  margin-top: 0;
  text-indent: 0;
}

#footer > div.clear {
  margin: 0;
  padding: 0;
}

#footer .footer-breaker {
  background: #888;
  border-bottom: solid 1px #eee;
  height: 1px;
  margin: 2px 0 11px 85px;
}

#footer .footer-logo {
  position: relative;
}

#footer .footer-logo h1 {
  float: left;
}

#footer .footer-logo h1 a {
  color: #fff;
  display: block;
  left: 0;
  font-size: 16px;
  position: absolute;
  top: -7px;
  /* width: 50px */
}

#footer .footer-logo h1 a:hover {
  color: #e28325;
}

#footer a,
#footer a:visited {
  color: #fff;
  text-decoration: none;
}

#footer a:hover {
  color: #e28325;
  text-decoration: underline;
}

#footer .left p {
  margin-bottom: 3px;
}

#footer .right div {
  margin-left: 20px;
  margin-top: -8px;
  float: right;
}

#footer .right div a {
  display: inline-block;
  padding-bottom: 2px;
}

#footer .right div h3 {
  border-bottom: 1px solid #fff;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
  margin-top: 0px;
}

@-o-keyframes colored-pulse /* Opera */ {
  0% {
    -moz-box-shadow: 0 0 4px #bb1c19;
    -webkit-box-shadow: 0 0 4px #bb1c19;
    box-shadow: 0 0 4px #bb1c19;
  }

  40% {
    -moz-box-shadow: 0 0 15px #bb1c19;
    -webkit-box-shadow: 0 0 15px #bb1c19;
    box-shadow: 0 0 15px #bb1c19;
  }

  60% {
    -moz-box-shadow: 0 0 15px #bb1c19;
    -webkit-box-shadow: 0 0 15px #bb1c19;
    box-shadow: 0 0 15px #bb1c19;
  }

  100% {
    -moz-box-shadow: 0 0 4px #bb1c19;
    -webkit-box-shadow: 0 0 4px #bb1c19;
    box-shadow: 0 0 4px #bb1c19;
  }
}
.footer-sd {
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  gap: 1rem !important;
  max-width: 100% !important;
}

.pulse-loader {
  background-color: #bb1c19;
  -moz-animation: colored-pulse 4s infinite;
  -webkit-animation: colored-pulse 4s infinite;
  -o-animation: colored-pulse 4s infinite;
  animation: colored-pulse 4s infinite;
}

#header_push {
  height: 0px;
}

#header {
  background-color: #0555ae;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#0555ae),
    to(#044b99)
  );
  background: -moz-linear-gradient(top, #0555ae, #044b99);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0555AE', endColorstr='#044B99');
  background-color: #044ea0;
  color: #eee;
  font-size: 140%;
  height: 70px;
  /* position: absolute; */
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 700;
}

#header #top_header {
  background: #044288;
  font-size: 12px;
  height: 20px;
  overflow: hidden;
  padding: 2px 0px;
  width: auto;
}

#header a {
  color: #eee;
}

#header a:hover {
  color: #e28325;
}

#header a:active {
  color: #bb1c19;
}
.fixed-width {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

#header #main_header {
  float: left;
  font-size: 100%;
  font-weight: normal;
  padding-top: 5px;
}

#header #main_header #header_logo {
  margin-top: 10px;
}

@media screen and (max-width: 35.5em) {
  #header #main_header #header_logo {
    height: 38px;
    margin-top: 19px;
  }
}

@media screen and (max-width: 23.5em) {
  #header #main_header #header_logo {
    height: 32px;
    margin-top: 18px;
  }
}

#header #user_header {
  display: inline-block;
  float: right;
  font-size: 76%;
  margin-right: 20px;
  position: relative;
}

#header #user_header .sign-out-link-container {
  margin-top: 35px;
  margin-right: 86px;
}

#header #user_header .sign-out-link-container a {
  display: inline-block;
  margin-left: 20px;
}

@media screen and (max-width: 35.5em) {
  #header #user_header .sign-out-link-container a {
    margin-left: 15px;
  }

  #header #user_header .sign-out-link-container a.hide-sm {
    display: none;
  }
}

@media screen and (max-width: 23.5em) {
  #header #user_header .sign-out-link-container a.hide-xs {
    display: none;
  }
}

#header #user_header .signed-in-link-container {
  margin-top: 22px;
}

#header #user_header .signed-in-link-container a.nav-link {
  display: inline-block;
  margin-left: 15px;
  margin-right: 20px;
  margin-top: 10px;
  vertical-align: top;
}
.signed-in-link-container {
  margin-top: 0px !important;
}

@media screen and (max-width: 48em) {
  #header #user_header .signed-in-link-container a.nav-link {
    display: none;
  }
}

#header #user_header .signed-in-link-container a.sign-in-button {
  /* background: url(/assets/header_buttons-bacd8d7fb0982affdccb6e0f50e3c0db7d0323e52edc41e18268001e09275999.png) no-repeat; */
  color: #fff;
  display: inline-block;
  height: 40px;
  text-decoration: none;
}

#header
  #user_header
  .signed-in-link-container
  a.sign-in-button.user-header-sign-in {
  background-position: 0px -100px;
  width: 114px;
}

#header
  #user_header
  .signed-in-link-container
  a.sign-in-button.user-header-sign-in:hover {
  background-position: 0px -150px;
}

#header
  #user_header
  .signed-in-link-container
  a.sign-in-button.user-header-sign-in:active {
  background-position: 0px -100px;
}

#header
  #user_header
  .signed-in-link-container
  a.sign-in-button.user-header-sign-up {
  background-position: 0px -200px;
  width: 125px;
}

#header
  #user_header
  .signed-in-link-container
  a.sign-in-button.user-header-sign-up:hover {
  background-position: 0px -250px;
}

#header
  #user_header
  .signed-in-link-container
  a.sign-in-button.user-header-sign-up:active {
  background-position: 0px -200px;
}

@media screen and (max-width: 35.5em) {
  #header #user_header .user-header-sign-in {
    display: none !important;
  }
}

#header #header_profile_icon_container {
  position: absolute;
  top: 18px;
  right: -1px;
}

.header-profile-icon img.current-user-profile-pic {
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}

#header_profile_menu {
  background-color: #ffffff;
  border: 1px solid #dedede;
  border-radius: 5px;
  box-shadow: 1px 1px 2px #eeeeee;
  left: -130px;
  margin-top: 5px;
  padding: 0;
  position: absolute;
  width: 180px;
  z-index: 1000;
}

a.header-profile-menu-item {
  color: #1c3361 !important;
  display: block;
  height: 20px;
  padding: 6px 12px;
  text-align: center;
  border-bottom: 1px solid #dadada;
  position: relative;
}

a.header-profile-menu-item:last-child {
  border-bottom: none;
}

a.header-profile-menu-item:hover {
  background-color: #f3f3f3 !important;
  color: #2c43a1 !important;
  text-decoration: none;
}
.nav-link {
  font-size: 18px;
  cursor: pointer;
}

#static_how_it_works_page .how-it-works-point {
  color: #202020;
  position: relative;
}

#static_how_it_works_page .how-it-works-point-split {
  position: relative;
  display: block;
  padding: 50px 60px;
  z-index: 0;
}

#static_how_it_works_page .how-it-works-point-split img,
#static_how_it_works_page .how-it-works-point-split .fa,
#static_how_it_works_page .how-it-works-point-split .fa-regular,
#static_how_it_works_page .how-it-works-point-split .fa-solid {
  color: #1a5a87;
  font-size: 110px;
  margin-top: 10px;
}

@media screen and (max-width: 48em) {
  #static_how_it_works_page .how-it-works-point-split {
    padding: 40px 5px;
  }

  #static_how_it_works_page .how-it-works-point-split img,
  #static_how_it_works_page .how-it-works-point-split .fa,
  #static_how_it_works_page .how-it-works-point-split .fa-regular,
  #static_how_it_works_page .how-it-works-point-split .fa-solid {
    margin-top: 35px;
  }
}

#static_how_it_works_page .how-it-works-integer {
  color: #eaf2f6;
  position: absolute;
  top: 66px;
  left: 22px;
  font-size: 144px;
  font-family: Arial, Helvetica, sans-serif;
  z-index: -1;
}

#static_how_it_works_page .how-it-works-subtitle {
  font-size: 24px;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 15px;
  z-index: 4;
  padding-left: 10px;
}

#static_how_it_works_page .how-it-works-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  z-index: 3;
  padding-left: 10px;
}

#static_index_page #top_section_text {
  padding-top: 65px;
  padding-bottom: 65px;
  display: inline-block;
  width: 45%;
}

#static_index_page #top_section_text h1 {
  font-size: 32px;
  font-weight: 400;
  line-height: 36px;
}

#static_index_page #top_section_text .top-section-subtext {
  font-weight: 400;
  margin: 20px 0 40px;
}

#static_index_page #try_now_button {
  margin-left: 6px;
}

#static_index_page #top_section_image {
  display: inline-block;
  width: 49%;
  text-align: center;
  float: right;
  margin-top: 50px;
}

#static_index_page #top_section_image_mobile {
  display: none;
}

@media screen and (max-width: 35.5em) {
  #static_index_page #top_section_image_mobile {
    display: block;
    text-align: center;
    margin-top: 33px;
  }

  #static_index_page #top_section_image {
    display: none;
  }

  #static_index_page #top_section_text {
    padding-top: 35px;
    padding-bottom: 35px;
    display: flex;
    gap: 1rem;
    text-align: center;
    width: 100%;
  }
}

#static_index_page #benefits_section {
  background-color: #044ea0;
  color: #fefefe;
  text-align: center;
  width: 100%;
  padding: 20px 0;
}

#static_index_page #benefits_section h3 {
  text-align: center;
  font-size: 26px;
  padding-top: 50px;
  padding-bottom: 40px;
}

#static_index_page #benefits_section .benefit {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  height: 100%;
  align-items: center;
  flex-direction: column;
  white-space: pre-line;
  max-width: 30%;
}
.benefit-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

#static_index_page #benefits_section .benefit .benefit-image img {
  /* width: 60px; */
}

#static_index_page #benefits_section .benefit .benefit-title {
  font-size: 21px;
  margin: 10px 0;
}

#static_index_page #benefits_section .benefit .benefit-description {
  padding: 0;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 48em) {
  #static_index_page #benefits_section .benefit-hide-mobile {
    /* display: none; */
  }
}

#static_index_page #why_section {
  padding-top: 65px;
  padding-bottom: 70px;
}

#static_index_page #why_section #why_section_part1,
#static_index_page #why_section #why_section_part2 {
  /* display: inline-block; */
  /* width: 49%; */
}

#static_index_page #why_section #why_section_part2 {
  float: right;
  text-align: center;
}

#static_index_page #why_section #why_section_pretitle {
  text-transform: capitalize;
  font-size: 12px;
  margin-top: 10px;
}

#static_index_page #why_section #why_section_title {
  max-width: 310px;
  margin: 8px 0 16px;
  font-size: 28px;
  line-height: 35px;
}

#static_index_page #why_section #why_section_explanation {
  margin-bottom: 20px;
  max-width: 370px;
  font-size: 13px;
}

#static_index_page #why_section .why-section-text-emphasis {
  color: #e28325;
}

#static_index_page #why_section #why_section_diagram {
  margin-right: 20px;
  margin-top: 50px;
}

@media screen and (max-width: 48em) {
  #static_index_page #why_section #why_section_part1 {
    text-align: center;
    width: 100%;
  }

  #static_index_page #why_section #why_section_part2 {
    /* display: none; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  #static_index_page #why_section #why_section_title,
  #static_index_page #why_section #why_section_explanation {
    margin-left: auto;
    margin-right: auto;
  }
}

/* input:not([type="submit"]):not([type="checkbox"]) {
    border: 1px solid #BBB;
    border-radius: 16px;
    font-size: 14px;
    font-family: "Inter", "Lucida Grande", "Lucida Sans", Geneva, Verdana, sans-serif;
    padding: 6px 10px;
    width: 100%
}

input:not([type="submit"]):not([type="checkbox"]):focus {
    outline: 0;
    box-shadow: 0 0 2px 2px #0093E6
}

textarea {
    border: 1px solid #BBB;
    border-radius: 16px;
    font-size: 14px;
    font-family: "Inter", "Lucida Grande", "Lucida Sans", Geneva, Verdana, sans-serif;
    padding: 6px 10px;
    width: 100%
} 

textarea:focus {
    outline: 0;
    box-shadow: 0 0 2px 2px #0093E6
}

.select-container {
    background: #fff url("data:image/png;base64,R0lGODlhDwAUAIABAAAAAP///yH5BAEAAAEALAAAAAAPABQAAAIXjI+py+0Po5wH2HsXzmw//lHiSJZmUAAAOw==") no-repeat 98% 50%;
    border: 1px solid #BBB;
    border-radius: 16px;
    display: inline-block;
    overflow: hidden
}

.select-container:hover {
    border-color: #666
}

.select-container:focus {
    border-color: #044EA0;
    outline: none
}

.select-container select {
    padding: 4px 18px 4px 12px;
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 12px;
    font-family: "Inter", "Lucida Grande", "Lucida Sans", Geneva, Verdana, sans-serif
}

.select-container select:focus {
    outline: none
}

input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--form-background);
    margin: 0;
    font: inherit;
    color: #0093E6;
    width: 1.3em;
    height: 1.3em;
    border: 0.15em solid #BBB;
    border-radius: 0.3em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    background-color: #044EA0
}

input[type="checkbox"]:checked::before {
    transform: scale(1)
}

input[type="checkbox"]:focus {
    outline: 2px solid currentColor;
    outline-offset: 2px
}

input[type="checkbox"]:disabled {
    --form-control-color: var(--form-control-disabled);
    color: var(--form-control-disabled);
    cursor: not-allowed
}
*/
#insurance_output p:not(:last-child),
#insurance_output table:not(:last-child),
#insurance_output h1:not(:last-child),
#insurance_output h2:not(:last-child),
#insurance_output h3:not(:last-child),
#insurance_output h4:not(:last-child),
#insurance_output h5:not(:last-child),
#insurance_output h6:not(:last-child) {
  margin-bottom: 20px;
}

#insurance_output h1,
#insurance_output h2,
#insurance_output h3,
#insurance_output h4,
#insurance_output h5,
#insurance_output h6 {
  font-size: 100% !important;
}

#insurance_output p,
#insurance_output code {
  white-space: pre-wrap;
}

#insurance_output ul,
#insurance_output ol {
  list-style-position: inside;
}

#insurance_output table {
  border-collapse: collapse;
  border: 1px solid #d0d0d0 !important;
  width: 100%;
}

#insurance_output table th,
#insurance_output table td {
  border: 1px solid #d0d0d0 !important;
  padding: 6px !important;
  text-align: left;
}

#library_show_page #top_area {
  /* background-image: url(/assets/usa2-a4833312bbc4411cfadf624000d015058905e7079132a0605eb44869590c6610.jpg); */
  background-size: cover;
  background-position-y: 50%;
  color: #fff;
  min-height: 130px;
  padding: 100px 0 35px;
  position: relative;
  text-align: center;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    2px 2px 0 #000;
}

@media screen and (max-width: 35.5em) {
  #library_show_page #top_area {
    padding: 70px 0 0px;
  }
}

#library_show_page #top_area h1 {
  font-size: 32px;
}

@media screen and (max-width: 48em) {
  #library_show_page #top_area h1 {
    font-size: 24px;
  }
}

#library_show_page #top_area #top_area_subtitle {
  margin-top: 40px;
}

#library_show_page #library_files_container {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

#library_show_page #library_files_container a.library-file {
  display: inline-block;
  height: 220px;
  width: 160px;
  margin: 10px 20px;
  padding: 12px;
  border-radius: 4px;
}

#library_show_page #library_files_container a.library-file img.thumbnail {
  width: 140px;
  height: 190px;
}

#library_show_page #library_files_container a.library-file .library-file-title {
  text-align: center;
  font-size: 15px;
  display: block;
}

#library_show_page #library_files_container a.library-file:hover {
  background-color: #044ea0;
  text-decoration: none;
}

#library_show_page
  #library_files_container
  a.library-file:hover
  .library-file-title {
  color: #fefefe;
  text-decoration: none;
}

#library_show_page #library_files_container a.library-file:active {
  background-color: #0093e6;
  text-decoration: none;
}

#library_show_page
  #library_files_container
  a.library-file:active
  .library-file-title {
  color: #fefefe;
  text-decoration: none;
}

.bordered {
  border: 1px solid #999;
}

.left {
  float: left;
  display: inline;
}

.right {
  float: right;
  display: inline;
}

.clear {
  clear: both;
}

.relative {
  position: relative;
}

.display-none {
  display: none;
}

.hidden {
  visibility: hidden;
}

.center {
  text-align: center;
}

#minimalist_header_push {
  height: 56px;
}

#minimalist_header {
  background-color: #0555ae;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#0555ae),
    to(#044b99)
  );
  background: -moz-linear-gradient(top, #0555ae, #044b99);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0555AE', endColorstr='#044B99');
  background-color: #044ea0;
  color: #eee;
  position: relative;
  font-size: 140%;
  height: 56px;
  width: 100%;
  z-index: 700;
}

#minimalist_header #top_header {
  background: #044288;
  font-size: 12px;
  height: 20px;
  overflow: hidden;
  padding: 2px 0px;
  width: auto;
}

#minimalist_header #top_header ul li {
  display: inline;
  margin: 0 10px;
}

#minimalist_header a {
  color: #eee;
}

#minimalist_header a:hover {
  color: #e28325;
}

#minimalist_header a:active {
  color: #bb1c19;
}

#minimalist_header #main_header {
  float: left;
  font-size: 100%;
  font-weight: normal;
  padding-top: 10px;
  margin-left: 20px;
}

#minimalist_header #user_header {
  display: inline-block;
  float: right;
  font-size: 76%;
  margin-right: 16px;
  position: relative;
}

#minimalist_header #user_header .sign-out-link-container {
  margin-top: 20px;
  margin-right: 58px;
}

#minimalist_header #user_header .sign-out-link-container a {
  display: inline-block;
  margin-left: 20px;
}

@media screen and (max-width: 35.5em) {
  #minimalist_header #user_header .sign-out-link-container a {
    margin-left: 15px;
  }

  #minimalist_header #user_header .sign-out-link-container a.hide-sm {
    display: none;
  }
}

#minimalist_header #user_header .sign-in-buttons {
  padding-top: 9px;
}

#minimalist_header #user_header .sign-in-buttons > div {
  display: inline-block;
}

#minimalist_header #user_header .sign-in-buttons a {
  /* background: url(/assets/header_buttons-bacd8d7fb0982affdccb6e0f50e3c0db7d0323e52edc41e18268001e09275999.png) no-repeat; */
  color: #fff;
  display: inline-block;
  height: 40px;
  text-decoration: none;
}

#minimalist_header #user_header .sign-in-buttons a.user-header-sign-in {
  background-position: 0px -100px;
  width: 114px;
}

#minimalist_header #user_header .sign-in-buttons a.user-header-sign-in:hover {
  background-position: 0px -150px;
}

#minimalist_header #user_header .sign-in-buttons a.user-header-sign-in:active {
  background-position: 0px -100px;
}

#minimalist_header #user_header .sign-in-buttons a.user-header-sign-up {
  background-position: 0px -200px;
  width: 125px;
}

#minimalist_header #user_header .sign-in-buttons a.user-header-sign-up:hover {
  background-position: 0px -250px;
}

#minimalist_header #user_header .sign-in-buttons a.user-header-sign-up:active {
  background-position: 0px -200px;
}

@media screen and (max-width: 35.5em) {
  #minimalist_header #user_header .sign-in-buttons .user-header-sign-in {
    display: none !important;
  }
}

#minimalist_header #header_profile_icon_container {
  position: absolute;
  top: 10px;
  right: -1px;
}

#modal_overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(250, 250, 250, 0.8);
  z-index: 1000;
}

.modal {
  background-color: #f3f3f3;
  border: 2px solid #044ea0;
  color: #111;
  display: none;
  margin: 4px;
  z-index: 1100;
  top: 12%;
  -moz-box-shadow: 0 0px 6px #444;
  -webkit-box-shadow: 0 0px 6px #444;
  box-shadow: 0 0px 6px #444;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -khtml-border-radius: 4px;
  border-radius: 4px;
  position: fixed;
  left: 50%;
  width: 600px;
  margin-left: -320px;
}

@media screen and (max-width: 35.5em) {
  .modal {
    width: 450px;
    margin-left: -225px;
  }
}

@media screen and (max-width: 400px) {
  .modal {
    width: 370px;
    margin-left: -185px;
  }
}

.modal.narrow {
  width: 400px;
  margin-left: -220px;
}

.modal.wide {
  width: 700px;
  margin-left: -370px;
}

.modal .title {
  background-color: #044ea0;
  color: #fff;
  display: block;
  font-size: 140%;
  font-weight: bold;
  padding: 5px 0px;
  margin: 0;
  text-align: center;
}

.modal .contents {
  padding: 6px 20px;
}

.modal h3 {
  margin-top: 0px;
  margin-bottom: 5px;
}

.modal .modal-buttons {
  padding: 8px 0px;
  text-align: right;
}

.modal .modal-buttons .button {
  margin-left: 2px;
  text-align: center;
}

.modal .modal-buttons .button:hover {
  text-decoration: none;
}

.modal a.close.x {
  display: block;
  position: absolute;
  top: -5px;
  right: 5px;
  color: #aaa;
  cursor: pointer;
  text-align: right;
  text-decoration: none;
  font-family: Helvetica, sans-serif;
  font-size: 13px;
}

.modal a.close.x:hover {
  color: #e28325;
  text-decoration: none;
}

.modal .thinking,
.modal .success,
.modal .error {
  display: none;
  text-align: center;
}

#sign_in_modal {
  text-align: center;
}

#new_directory_modal input[name="name"] {
  width: 96%;
}

#new_file_modal .contents {
  max-height: 500px;
  overflow-y: auto;
}

#new_file_modal .new-file-modal-extra-options {
  text-align: center;
  margin-bottom: 6px;
}

#new_file_modal .new-file-modal-extra-options .checkbox-container {
  display: inline-block;
}

#new_file_modal .new-file-modal-extra-options label {
  display: inline;
  margin-left: 4px;
}

#new_file_modal .new-file-modal-extra-options label.disabled {
  color: #aaa;
}

.new-leads-landing-page img {
  display: block;
  max-width: 100%;
}

.new-leads-landing-page h1,
.new-leads-landing-page h2,
.new-leads-landing-page h3,
.new-leads-landing-page h4,
.new-leads-landing-page h5,
.new-leads-landing-page h6 {
  font-weight: 100;
}

@media (max-width: 980px) {
  .new-leads-landing-page h1,
  .new-leads-landing-page h2,
  .new-leads-landing-page h3,
  .new-leads-landing-page h4,
  .new-leads-landing-page h5,
  .new-leads-landing-page h6,
  .new-leads-landing-page .tcm {
    text-align: center;
  }

  .new-leads-landing-page .mxm {
    margin: 0 auto;
  }
}

.new-leads-landing-page h2 {
  font-size: 50px;
  line-height: 1.2;
}

.new-leads-landing-page h2.insurance-title {
  font-size: 42px;
}

.new-leads-landing-page h3 {
  font-size: 40px;
  color: #000;
  line-height: 1.2;
}

.new-leads-landing-page #how_it_works_section h3 {
  font-weight: 400;
}

.new-leads-landing-page #how_it_works_section .color-emphasis {
  color: #044ea0;
}

.new-leads-landing-page #faq_section h3 {
  font-weight: 600;
}

@media (max-width: 980px) {
  .new-leads-landing-page h2 {
    font-size: 45px;
  }

  .new-leads-landing-page h3 br {
    display: none;
  }
}

@media (max-width: 768px) {
  .new-leads-landing-page h2 {
    font-size: 40px;
  }

  .new-leads-landing-page h3 {
    font-size: 35px;
  }
}

.new-leads-landing-page h6 {
  font-size: 25px;
  line-height: 1.2;
}

@media (max-width: 768px) {
  .new-leads-landing-page h6 {
    font-size: 22px;
  }
}

.new-leads-landing-page section {
  position: relative;
}

.new-leads-landing-page .container {
  max-width: 978px;
  width: 100%;
  padding: 0 9px;
  margin: 0 auto;
  box-sizing: border-box;
}

.new-leads-landing-page .zind {
  position: relative;
  z-index: 1500;
}

.new-leads-landing-page .zind2 {
  position: relative;
  z-index: 1700;
}

.new-leads-landing-page p {
  font-size: 16px;
  color: #282828;
  line-height: 1.9;
  margin-top: 20px;
}

.new-leads-landing-page p:first-child {
  margin-top: 0;
}

.new-leads-landing-page .wc h2,
.new-leads-landing-page .wc h6,
.new-leads-landing-page .wc p,
.new-leads-landing-page .whitec {
  color: #fff;
}

.new-leads-landing-page .wc p {
  max-width: 280px;
}

@media (max-width: 768px) {
  .new-leads-landing-page .wc p {
    margin-right: auto;
    margin-left: auto;
  }
}

.new-leads-landing-page .fl,
.new-leads-landing-page .fe {
  display: flex;
  flex-wrap: wrap;
}

.new-leads-landing-page .fe {
  justify-content: flex-end;
}

.new-leads-landing-page .va,
.new-leads-landing-page nav,
.new-leads-landing-page nav ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media (max-width: 800px) {
  .new-leads-landing-page nav ul {
    display: none;
  }
}

.new-leads-landing-page .spb {
  justify-content: space-between;
}

.new-leads-landing-page .jc {
  justify-content: center;
}

.new-leads-landing-page .gradient {
  background: linear-gradient(45deg, #004688, #002145);
  background-color: #002145;
}

.new-leads-landing-page .white {
  background-color: #fff;
}

.new-leads-landing-page .lblue {
  background-color: #dce6f2;
}

.new-leads-landing-page .blue {
  background-color: #044ea0;
}

.new-leads-landing-page .tc {
  text-align: center;
}

.new-leads-landing-page .mx {
  margin: 0 auto;
}

.new-leads-landing-page .pad {
  padding: 90px 0 100px 0;
}

.new-leads-landing-page .pad2 {
  padding: 85px 0;
}

@media (max-width: 980px) {
  .new-leads-landing-page .pad2 {
    padding: 30px 0 40px 0;
  }
}

.new-leads-landing-page .pt-out {
  padding: 145px 0 120px 0;
}

.new-leads-landing-page .pt {
  padding-top: 70px;
}

@media (max-width: 980px) {
  .new-leads-landing-page .pt {
    padding-top: 50px;
  }

  .new-leads-landing-page .pt-out {
    padding: 115px 0 80px 0;
  }
}

.new-leads-landing-page .pt20 {
  padding-top: 20px;
}

.new-leads-landing-page .pt25 {
  padding-top: 25px;
}

.new-leads-landing-page .pt30 {
  padding-top: 30px;
}

.new-leads-landing-page .mt70 {
  margin-top: 70px;
}

.new-leads-landing-page .mt80 {
  margin-top: 80px;
}

.new-leads-landing-page .new-leads-insurance-pic {
  margin-top: 28px;
}

@media (max-width: 980px) {
  .new-leads-landing-page .mt80 {
    margin-top: 40px;
  }
}

.new-leads-landing-page .maxw,
.new-leads-landing-page .maxw2 {
  position: relative;
  width: 100%;
}

.new-leads-landing-page .maxw {
  max-width: 400px;
}

.new-leads-landing-page .maxw2 {
  max-width: 640px;
}

@media (max-width: 980px) {
  .new-leads-landing-page .maxw,
  .new-leads-landing-page .maxw2 {
    max-width: 100%;
  }
}

.new-leads-landing-page .colfl {
  flex: 1 1;
}

@media (max-width: 980px) {
  .new-leads-landing-page .colfl {
    flex: none;
  }
}

.new-leads-landing-page .w40 {
  width: 40%;
}

.new-leads-landing-page .w50 {
  width: 50%;
}

.new-leads-landing-page .w60 {
  width: 60%;
}

@media (max-width: 980px) {
  .new-leads-landing-page .col {
    width: 100%;
  }

  .new-leads-landing-page .col:last-child {
    margin-top: 50px;
  }
}

.new-leads-landing-page .btn-block {
  display: flex;
  text-align: center;
}

@media (max-width: 980px) {
  .new-leads-landing-page .btn-block {
    justify-content: center;
  }
}

.new-leads-landing-page .top,
.new-leads-landing-page .bot {
  max-height: 80px;
  width: 100%;
  position: absolute;
  z-index: 1700;
  left: 50%;
  transform: translateX(-50%);
}

.new-leads-landing-page .bot {
  top: 99%;
}

.new-leads-landing-page .top {
  bottom: 99%;
}

.new-leads-landing-page form {
  width: 100%;
  max-width: 400px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  padding: 42px 30px 32px;
  text-align: center;
}

.new-leads-landing-page form h3 {
  font-size: 32px;
}

@media (max-width: 980px) {
  .new-leads-landing-page form {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .new-leads-landing-page form {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.new-leads-landing-page #lead_gen_form_success {
  font-size: 21px;
  margin-top: 40px;
}

.new-leads-landing-page .field label {
  position: absolute;
  left: 20px;
  top: -8px;
  font-size: 13px;
  line-height: 1.5;
  color: #0450a3;
  background-color: #fff;
  padding: 0 5px;
}

.new-leads-landing-page .field:hover label {
  color: #0093e6;
}

.new-leads-landing-page,
.new-leads-landing-page textarea {
  width: 100%;
  font-size: 16px;
  color: #000;
  background: #fff;
  border: 2px solid #c2d7e0;
  border-radius: 10px;
  padding: 0 20px;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: default-button;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.new-leads-landing-page {
  height: 53px;
}

.new-leads-landing-page #lead_gen_form_submit[type="submit"] {
  max-width: 300px;
  font-size: 24px;
  border: 0;
  height: auto;
  cursor: pointer;
  margin-top: 29px;
  line-height: 1.2;
  color: #fff;
  background-color: #002e62;
  border-radius: 70px;
  padding: 16px 30px;
  margin-left: auto;
  margin-right: auto;
}

.new-leads-landing-page #lead_gen_form_submit[type="submit"]:hover {
  background-color: #2cab49;
}

.new-leads-landing-page #lead_gen_form_submit.disabled[type="submit"] {
  background-color: #c2d7e0;
  cursor: not-allowed;
}

.new-leads-landing-page .fields {
  padding-top: 5px;
}

.new-leads-landing-page .field {
  width: 100%;
  position: relative;
  margin-top: 29px;
}

.new-leads-landing-page {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 23px;
}

.new-leads-landing-page {
  display: block;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: capitalize;
  margin-right: 17px;
}

.new-leads-landing-page span {
  font-size: 12px;
  line-height: 1.6;
}

.new-leads-landing-page {
  margin-right: 0;
}

.new-leads-landing-page input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.new-leads-landing-page .checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background: #fff;
  border: 2px solid #c2d7e0;
  border-radius: 2px;
}

.new-leads-landing-page :hover input ~ .checkmark {
  background-color: #fff;
}

.new-leads-landing-page input:checked ~ .checkmark {
  background-color: #fff;
}

.new-leads-landing-page .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.new-leads-landing-page input:checked ~ .checkmark:after,
.new-leads-landing-page .checkmark.check:after {
  display: block;
}

.new-leads-landing-page .checkmark:after {
  content: "";
  width: 14px;
  height: 14px;
  background: #0450a3;
}

.new-leads-landing-page .testimonial-logo-img {
  max-height: 35px;
}

.new-leads-landing-page .testimonial-inline-logo {
  height: 28px;
  display: inline-block;
  top: 5px;
  position: relative;
}

.new-leads-landing-page .ai-chip-pic {
  position: absolute;
  z-index: 900;
  bottom: -34px;
  left: 40%;
  max-width: none;
}

@media (max-width: 980px) {
  .new-leads-landing-page .ai-chip-pic {
    display: none;
  }
}

.new-leads-landing-page .cite-desc p {
  margin-top: 0;
}

.new-leads-landing-page .cite {
  font-size: 21px;
  line-height: 1.5;
}

.new-leads-landing-page .testimonial {
  padding: 95px 0 120px 0;
  margin-top: -90px;
}

@media (max-width: 980px) {
  .new-leads-landing-page .testimonial {
    margin-top: -30px;
    padding: 85px 0 100px 0;
  }
}

.new-leads-landing-page .question {
  margin-top: 28px;
}

.new-leads-landing-page .lead-gen-question h6 {
  text-align: left;
}

.new-leads-landing-page .lead-gen-question {
  position: relative;
  cursor: pointer;
  background-color: #004797;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  padding: 24px 50px 22px 30px;
}

@media (max-width: 570px) {
  .new-leads-landing-page .lead-gen-question h6 {
    text-align: center;
  }

  .new-leads-landing-page .lead-gen-question {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 53px;
  }
}

.new-leads-landing-page .lead-gen-question:after {
  /* content: url(/assets/static_batch_page/arrow-8c4dd2a8a88e323a70a9037d45dc61ecc91eee267cee7dd5af28034524fd4acf.svg); */
  width: 13px;
  position: absolute;
  top: 29px;
  right: 30px;
  transform: none;
  transition: 0.2s;
}

@media (max-width: 570px) {
  .new-leads-landing-page .lead-gen-question:after {
    left: 50%;
    transform: translateX(-50%);
    top: auto;
    bottom: 15px;
  }
}

.new-leads-landing-page .active-question.lead-gen-question:after {
  transform: rotate(90deg);
  transition: 0.2s;
}

@media (max-width: 570px) {
  .new-leads-landing-page .lead-gen-question:after {
    transform: rotate(90deg);
  }

  .new-leads-landing-page .active-question.lead-gen-question:after {
    transform: rotate(-90deg);
  }
}

.new-leads-landing-page .answer-text {
  display: none;
  padding: 20px 30px 12px 30px;
}

@media (max-width: 570px) {
  .new-leads-landing-page .answer-text {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.new-leads-landing-page .answer-text p {
  color: #dce6f2;
}

.new-leads-landing-page #question1 .answer-text {
  display: block;
}

#users_opt_out_page .email-opt-out-text {
  font-size: 13px;
}

#users_opt_out_page .settings-label {
  display: inline;
  margin-left: 4px;
}

#users_opt_out_page .checkbox-container {
  display: inline-block;
}

.pretty-form label {
  display: block;
  font-size: 13px;
  padding: 2px 0;
  margin-top: 10px;
}

.pretty-form input[type="submit"] {
  cursor: pointer;
  margin-top: 20px;
}

.pretty-form .helpful-secondary-info {
  font-size: 11px;
  padding: 3px 12px 3px;
  text-align: left;
  line-height: 12px;
}

#static_pricing_page #duration_picker {
  font-size: 0;
  line-height: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  display: inline-block;
}

#static_pricing_page #duration_picker a {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #fff),
    color-stop(60%, #fff),
    color-stop(100%, #e0e0e0)
  );
  border: solid 1px #ddd;
  cursor: pointer;
  color: #585858;
  display: inline-block;
  height: 35px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  text-decoration: none;
  width: 170px;
}

#static_pricing_page #duration_picker a:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #fff),
    color-stop(60%, #fff),
    color-stop(100%, #d0d0d0)
  );
}

#static_pricing_page #duration_picker a:active {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #fff),
    color-stop(60%, #fff),
    color-stop(100%, silver)
  );
}

#static_pricing_page #duration_picker a.selected {
  cursor: default;
  color: #fefefe;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #044ea0),
    color-stop(60%, #044ea0),
    color-stop(100%, #004568)
  );
}

#static_pricing_page #duration_picker a.selected:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #044ea0),
    color-stop(60%, #044ea0),
    color-stop(100%, #004568)
  );
}

#static_pricing_page #duration_picker a.selected:active {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #044ea0),
    color-stop(60%, #044ea0),
    color-stop(100%, #004568)
  );
}

#static_pricing_page #duration_picker a#duration_picker_monthly {
  border-right: none;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

#static_pricing_page #duration_picker a#duration_picker_yearly {
  border-left: none;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

#static_pricing_page #duration_picker a span {
  display: block;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 4px;
}

#static_pricing_page #duration_picker a .duration-picker-savings {
  color: #2f5399;
  display: inline;
  font-size: 12px;
}

#static_pricing_page #duration_picker a.selected .duration-picker-savings {
  color: #fefefe;
}

#static_pricing_page #plans {
  /* border-left: 1px solid #ddd; */
  /* border-right: 1px solid #ddd; */
  margin: 30px auto 10px;
  text-align: center;
  /* width: 764px; */
  display: flex;
  justify-content: center;
  /* box-shadow: 5px 5px #f6f6f6; */
}

@media screen and (max-width: 35.5em) {
  #static_pricing_page #plans {
    width: 377px;
  }
}

#static_pricing_page .plan {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  float: left;
  padding: 5px;
  position: relative;
  width: 250px;
}

@media screen and (max-width: 35.5em) {
  #static_pricing_page .plan {
    padding: 5px 1px;
  }
}

#static_pricing_page .plan.left-border {
  border-left: 1px solid #eee;
}

#static_pricing_page .plan.popular {
  border: 1px solid #aabbcc;
  margin-bottom: -14px;
  margin-top: -10px;
  padding-bottom: 14px;
  padding-top: 14px;
  z-index: 10;
}

@media screen and (max-width: 35.5em) {
  #static_pricing_page .plan.popular {
    padding-bottom: 7px;
  }
}

#static_pricing_page .plan.popular img.banner {
  left: -5px;
  position: absolute;
  top: -5px;
}

#static_pricing_page .plan h2 {
  color: #1c3362;
  font-size: 26px;
  font-weight: 400;
  margin: 14px 0 4px;
}

#static_pricing_page .plan .pricing {
  color: #1c3361;
  padding: 11px 0 11px;
  height: 40px;
}

#static_pricing_page .plan .pricing div.business {
  padding-top: 10px;
  font-size: 14px;
}

#static_pricing_page .plan .pricing .dollar {
  color: #1c3361;
  font-size: 14px;
  vertical-align: 10px;
}

#static_pricing_page .plan .pricing .price {
  font-size: 26px;
}

#static_pricing_page .plan .pricing .permo {
  font-size: 12px;
}

#static_pricing_page .plan .pricing .discount {
  font-size: 10px;
}

#static_pricing_page .plan .inner {
  background-color: #eee;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fcfcfc),
    to(#e7e7e7)
  );
  background-position: center top;
  background-repeat: repeat-x;
  border: 1px solid #ddd;
  margin: 2px;
  position: relative;
  border-radius: 6px;
}

#static_pricing_page .plan .inner .start {
  bottom: 6px;
  /* position: absolute; */
  width: 100%;
}

#static_pricing_page .plan .inner .start .button {
  /* width: 108px; */
}
#plans {
  display: flex !important;
  flex-wrap: wrap !important;
  row-gap: 2rem !important;
  column-gap: 1rem;
}

#static_pricing_page .plan .benefits {
  min-height: 210px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
}

#static_pricing_page .plan .benefit {
  border-bottom: 1px dotted #666;
  font-size: 11px;
  margin: 6px;
  padding: 4px;
  position: relative;
}

#static_pricing_page .plan .benefit:last-child {
  border-bottom: none;
}

#static_pricing_page .plan .your-plan {
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 12px;
}

#static_pricing_page .quote-container {
  margin: 50px auto 50px;
  max-width: 763px;
  text-align: center;
}

@media screen and (max-width: 35.5em) {
  #static_pricing_page .quote-container {
    max-width: 330px;
  }
}

#static_pricing_page .quote-container .quote {
  font-size: 16px;
  display: inline-block;
  position: relative;
}

#static_pricing_page .quote-container .quote::before,
#static_pricing_page .quote-container .quote::after {
  font-size: 28px;
  font-family: "Helvetica Neue", Helvetica, Arial, Sans-Serif;
  position: absolute;
  top: 1px;
  color: #aaaaaa;
}

#static_pricing_page .quote-container .quote::before {
  content: "“";
  left: -13px;
}

#static_pricing_page .quote-container .quote::after {
  content: "”";
  right: -13px;
}

#static_pricing_page .quote-container .quote-author {
  font-size: 14px;
  font-style: italic;
  margin-top: 4px;
}

#static_pricing_page .plan-questions-container {
  border-top: 1px dotted #d0d0d0;
  padding-top: 20px;
  padding-bottom: 20px;
}

#static_pricing_page .plan-question-and-answer {
  margin: 20px;
}

#static_pricing_page .plan-question {
  margin-bottom: 8px;
  font-size: 15px;
}

.profile-head {
  margin: 20px 0px;
}

.profile-head .display-name {
  padding-top: 13px;
  margin-left: 62px;
}

.profile-pic {
  border-radius: 50%;
  background-color: #fff;
}

.profile-pic.profile-pic--large {
  height: 220px;
  width: 220px;
}

.profile-pic.profile-pic-small {
  height: 50px;
  width: 50px;
}

#purchase_credits_modal .amount-bonus-credits {
  font-size: 14px;
  margin-bottom: 1px;
}

#purchase_credits_modal .bonus-credits-select {
  display: inline-block;
}

#purchase_credits_modal #purchase_credits_stripe_container {
  min-height: 250px;
  margin-top: 10px;
}

#purchase_credits_modal .powered-by-stripe-logo {
  float: left;
  margin-top: 5px;
}

#purchase_credits_modal #purchase_credits_stripe_loading {
  padding-top: 90px;
  text-align: center;
}

#purchase_plan_modal #purchase_plan_modal_description {
  font-size: 16px;
  text-align: center;
  margin: 5px 0 12px;
}

#purchase_plan_modal #stripe_payment_element {
  min-height: 250px;
}

#purchase_plan_modal .powered-by-stripe-logo {
  float: left;
  margin-top: 5px;
}

#purchase_plan_modal #stripe_payment_element_loading {
  padding-top: 90px;
  text-align: center;
}

.pure-g {
  letter-spacing: -0.31em;
  letter-spacing: normal;
  word-spacing: -0.43em;
  text-rendering: optimizespeed;
  font-family: FreeSans, Arimo, "Droid Sans", Helvetica, Arial, sans-serif;
  display: -webkit-flex;
  -webkit-flex-flow: row wrap;
  display: -ms-flexbox;
  -ms-flex-flow: row wrap;
  -ms-align-content: flex-start;
  -webkit-align-content: flex-start;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
}

.pure-g {
  word-spacing: -0.43em;
}

.pure-u {
  display: inline-block;
  display: inline;
  zoom: 1;
  letter-spacing: normal;
  word-spacing: normal;
  vertical-align: top;
  text-rendering: auto;
}

.pure-g [class*="pure-u"] {
  font-family: "Inter", "Lucida Grande", "Lucida Sans", Geneva, Verdana,
    sans-serif;
}

.pure-u-1,
.pure-u-1-1,
.pure-u-1-2,
.pure-u-1-3,
.pure-u-2-3,
.pure-u-1-4,
.pure-u-3-4,
.pure-u-1-5,
.pure-u-2-5,
.pure-u-3-5,
.pure-u-4-5,
.pure-u-5-5,
.pure-u-1-6,
.pure-u-5-6,
.pure-u-1-8,
.pure-u-3-8,
.pure-u-5-8,
.pure-u-7-8,
.pure-u-1-12,
.pure-u-5-12,
.pure-u-7-12,
.pure-u-11-12,
.pure-u-1-24,
.pure-u-2-24,
.pure-u-3-24,
.pure-u-4-24,
.pure-u-5-24,
.pure-u-6-24,
.pure-u-7-24,
.pure-u-8-24,
.pure-u-9-24,
.pure-u-10-24,
.pure-u-11-24,
.pure-u-12-24,
.pure-u-13-24,
.pure-u-14-24,
.pure-u-15-24,
.pure-u-16-24,
.pure-u-17-24,
.pure-u-18-24,
.pure-u-19-24,
.pure-u-20-24,
.pure-u-21-24,
.pure-u-22-24,
.pure-u-23-24,
.pure-u-24-24 {
  display: inline-block;
  display: inline;
  zoom: 1;
  letter-spacing: normal;
  word-spacing: normal;
  vertical-align: top;
  text-rendering: auto;
}

.pure-u-1-24 {
  width: 4.1667%;
  width: 4.1357%;
}

.pure-u-1-12,
.pure-u-2-24 {
  width: 8.3333%;
  width: 8.3023%;
}

.pure-u-1-8,
.pure-u-3-24 {
  width: 12.5%;
  width: 12.469%;
}

.pure-u-1-6,
.pure-u-4-24 {
  width: 16.6667%;
  width: 16.6357%;
}

.pure-u-1-5 {
  width: 20%;
  width: 19.969%;
}

.pure-u-5-24 {
  width: 20.8333%;
  width: 20.8023%;
}

.pure-u-1-4,
.pure-u-6-24 {
  width: 25%;
  width: 24.969%;
}

.pure-u-7-24 {
  width: 29.1667%;
  width: 29.1357%;
}

.pure-u-1-3,
.pure-u-8-24 {
  width: 33.3333%;
  width: 33.3023%;
}

.pure-u-3-8,
.pure-u-9-24 {
  width: 37.5%;
  width: 37.469%;
}

.pure-u-2-5 {
  width: 40%;
  width: 39.969%;
}

.pure-u-5-12,
.pure-u-10-24 {
  width: 41.6667%;
  width: 41.6357%;
}

.pure-u-11-24 {
  width: 45.8333%;
  width: 45.8023%;
}

.pure-u-1-2,
.pure-u-12-24 {
  width: 50%;
  width: 49.969%;
}

.pure-u-13-24 {
  width: 54.1667%;
  width: 54.1357%;
}

.pure-u-7-12,
.pure-u-14-24 {
  width: 58.3333%;
  width: 58.3023%;
}

.pure-u-3-5 {
  width: 60%;
  width: 59.969%;
}

.pure-u-5-8,
.pure-u-15-24 {
  width: 62.5%;
  width: 62.469%;
}

.pure-u-2-3,
.pure-u-16-24 {
  width: 66.6667%;
  width: 66.6357%;
}

.pure-u-17-24 {
  width: 70.8333%;
  width: 70.8023%;
}

.pure-u-3-4,
.pure-u-18-24 {
  width: 75%;
  width: 74.969%;
}

.pure-u-19-24 {
  width: 79.1667%;
  width: 79.1357%;
}

.pure-u-4-5 {
  width: 80%;
  width: 79.969%;
}

.pure-u-5-6,
.pure-u-20-24 {
  width: 83.3333%;
  width: 83.3023%;
}

.pure-u-7-8,
.pure-u-21-24 {
  width: 87.5%;
  width: 87.469%;
}

.pure-u-11-12,
.pure-u-22-24 {
  width: 91.6667%;
  width: 91.6357%;
}

.pure-u-23-24 {
  width: 95.8333%;
  width: 95.8023%;
}

.pure-u-1,
.pure-u-1-1,
.pure-u-5-5,
.pure-u-24-24 {
  width: 100%;
}

.hide-sm {
  display: none;
}

.show-sm {
  display: inherit;
}

@media screen and (min-width: 35.5em) {
  .pure-u-sm-1,
  .pure-u-sm-1-1,
  .pure-u-sm-1-2,
  .pure-u-sm-1-3,
  .pure-u-sm-2-3,
  .pure-u-sm-1-4,
  .pure-u-sm-3-4,
  .pure-u-sm-1-5,
  .pure-u-sm-2-5,
  .pure-u-sm-3-5,
  .pure-u-sm-4-5,
  .pure-u-sm-5-5,
  .pure-u-sm-5-6,
  .pure-u-sm-1-8,
  .pure-u-sm-3-8,
  .pure-u-sm-5-8,
  .pure-u-sm-7-8,
  .pure-u-sm-1-12,
  .pure-u-sm-5-12,
  .pure-u-sm-7-12,
  .pure-u-sm-11-12,
  .pure-u-sm-1-24,
  .pure-u-sm-2-24,
  .pure-u-sm-3-24,
  .pure-u-sm-4-24,
  .pure-u-sm-5-24,
  .pure-u-sm-6-24,
  .pure-u-sm-7-24,
  .pure-u-sm-8-24,
  .pure-u-sm-9-24,
  .pure-u-sm-10-24,
  .pure-u-sm-11-24,
  .pure-u-sm-12-24,
  .pure-u-sm-13-24,
  .pure-u-sm-14-24,
  .pure-u-sm-15-24,
  .pure-u-sm-16-24,
  .pure-u-sm-17-24,
  .pure-u-sm-18-24,
  .pure-u-sm-19-24,
  .pure-u-sm-20-24,
  .pure-u-sm-21-24,
  .pure-u-sm-22-24,
  .pure-u-sm-23-24,
  .pure-u-sm-24-24 {
    display: inline-block;
    display: inline;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
  }

  .pure-u-sm-1-24 {
    width: 4.1667%;
    width: 4.1357%;
  }

  .pure-u-sm-1-12,
  .pure-u-sm-2-24 {
    width: 8.3333%;
    width: 8.3023%;
  }

  .pure-u-sm-1-8,
  .pure-u-sm-3-24 {
    width: 12.5%;
    width: 12.469%;
  }

  .pure-u-sm-4-24 {
    width: 16.6667%;
    width: 16.6357%;
  }

  .pure-u-sm-1-5 {
    width: 20%;
    width: 19.969%;
  }

  .pure-u-sm-5-24 {
    width: 20.8333%;
    width: 20.8023%;
  }

  .pure-u-sm-1-4,
  .pure-u-sm-6-24 {
    width: 25%;
    width: 24.969%;
  }

  .pure-u-sm-7-24 {
    width: 29.1667%;
    width: 29.1357%;
  }

  .pure-u-sm-1-3,
  .pure-u-sm-8-24 {
    width: 33.3333%;
    width: 33.3023%;
  }

  .pure-u-sm-3-8,
  .pure-u-sm-9-24 {
    width: 37.5%;
    width: 37.469%;
  }

  .pure-u-sm-2-5 {
    width: 40%;
    width: 39.969%;
  }

  .pure-u-sm-5-12,
  .pure-u-sm-10-24 {
    width: 41.6667%;
    width: 41.6357%;
  }

  .pure-u-sm-11-24 {
    width: 45.8333%;
    width: 45.8023%;
  }

  .pure-u-sm-1-2,
  .pure-u-sm-12-24 {
    width: 50%;
    width: 49.969%;
  }

  .pure-u-sm-13-24 {
    width: 54.1667%;
    width: 54.1357%;
  }

  .pure-u-sm-7-12,
  .pure-u-sm-14-24 {
    width: 58.3333%;
    width: 58.3023%;
  }

  .pure-u-sm-3-5 {
    width: 60%;
    width: 59.969%;
  }

  .pure-u-sm-5-8,
  .pure-u-sm-15-24 {
    width: 62.5%;
    width: 62.469%;
  }

  .pure-u-sm-2-3,
  .pure-u-sm-16-24 {
    width: 66.6667%;
    width: 66.6357%;
  }

  .pure-u-sm-17-24 {
    width: 70.8333%;
    width: 70.8023%;
  }

  .pure-u-sm-3-4,
  .pure-u-sm-18-24 {
    width: 75%;
    width: 74.969%;
  }

  .pure-u-sm-19-24 {
    width: 79.1667%;
    width: 79.1357%;
  }

  .pure-u-sm-4-5 {
    width: 80%;
    width: 79.969%;
  }

  .pure-u-sm-5-6,
  .pure-u-sm-20-24 {
    width: 83.3333%;
    width: 83.3023%;
  }

  .pure-u-sm-7-8,
  .pure-u-sm-21-24 {
    width: 87.5%;
    width: 87.469%;
  }

  .pure-u-sm-11-12,
  .pure-u-sm-22-24 {
    width: 91.6667%;
    width: 91.6357%;
  }

  .pure-u-sm-23-24 {
    width: 95.8333%;
    width: 95.8023%;
  }

  .pure-u-sm-1,
  .pure-u-sm-1-1,
  .pure-u-sm-5-5,
  .pure-u-sm-24-24 {
    width: 100%;
  }

  .hide-sm {
    display: inherit;
  }

  .show-sm {
    display: none;
  }
}

.hide-md {
  display: none;
}

@media screen and (min-width: 48em) {
  .pure-u-md-1,
  .pure-u-md-1-1,
  .pure-u-md-1-2,
  .pure-u-md-1-3,
  .pure-u-md-2-3,
  .pure-u-md-1-4,
  .pure-u-md-3-4,
  .pure-u-md-1-5,
  .pure-u-md-2-5,
  .pure-u-md-3-5,
  .pure-u-md-4-5,
  .pure-u-md-5-5,
  .pure-u-md-1-6,
  .pure-u-md-5-6,
  .pure-u-md-1-8,
  .pure-u-md-3-8,
  .pure-u-md-5-8,
  .pure-u-md-7-8,
  .pure-u-md-1-12,
  .pure-u-md-5-12,
  .pure-u-md-7-12,
  .pure-u-md-11-12,
  .pure-u-md-1-24,
  .pure-u-md-2-24,
  .pure-u-md-3-24,
  .pure-u-md-4-24,
  .pure-u-md-5-24,
  .pure-u-md-6-24,
  .pure-u-md-7-24,
  .pure-u-md-8-24,
  .pure-u-md-9-24,
  .pure-u-md-10-24,
  .pure-u-md-11-24,
  .pure-u-md-12-24,
  .pure-u-md-13-24,
  .pure-u-md-14-24,
  .pure-u-md-15-24,
  .pure-u-md-16-24,
  .pure-u-md-17-24,
  .pure-u-md-18-24,
  .pure-u-md-19-24,
  .pure-u-md-20-24,
  .pure-u-md-21-24,
  .pure-u-md-22-24,
  .pure-u-md-23-24,
  .pure-u-md-24-24 {
    display: inline-block;
    display: inline;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
  }

  .pure-u-md-1-24 {
    width: 4.1667%;
    width: 4.1357%;
  }

  .pure-u-md-1-12,
  .pure-u-md-2-24 {
    width: 8.3333%;
    width: 8.3023%;
  }

  .pure-u-md-1-8,
  .pure-u-md-3-24 {
    width: 12.5%;
    width: 12.469%;
  }

  .pure-u-md-1-6,
  .pure-u-md-4-24 {
    width: 16.6667%;
    width: 16.6357%;
  }

  .pure-u-md-1-5 {
    width: 20%;
    width: 19.969%;
  }

  .pure-u-md-5-24 {
    width: 20.8333%;
    width: 20.8023%;
  }

  .pure-u-md-1-4,
  .pure-u-md-6-24 {
    width: 25%;
    width: 24.969%;
  }

  .pure-u-md-7-24 {
    width: 29.1667%;
    width: 29.1357%;
  }

  .pure-u-md-1-3,
  .pure-u-md-8-24 {
    width: 33.3333%;
    width: 33.3023%;
  }

  .pure-u-md-3-8,
  .pure-u-md-9-24 {
    width: 37.5%;
    width: 37.469%;
  }

  .pure-u-md-2-5 {
    width: 40%;
    width: 39.969%;
  }

  .pure-u-md-5-12,
  .pure-u-md-10-24 {
    width: 41.6667%;
    width: 41.6357%;
  }

  .pure-u-md-11-24 {
    width: 45.8333%;
    width: 45.8023%;
  }

  .pure-u-md-1-2,
  .pure-u-md-12-24 {
    width: 50%;
    width: 49.969%;
  }

  .pure-u-md-13-24 {
    width: 54.1667%;
    width: 54.1357%;
  }

  .pure-u-md-7-12,
  .pure-u-md-14-24 {
    width: 58.3333%;
    width: 58.3023%;
  }

  .pure-u-md-3-5 {
    width: 60%;
    width: 59.969%;
  }

  .pure-u-md-5-8,
  .pure-u-md-15-24 {
    width: 62.5%;
    width: 62.469%;
  }

  .pure-u-md-2-3,
  .pure-u-md-16-24 {
    width: 66.6667%;
    width: 66.6357%;
  }

  .pure-u-md-17-24 {
    width: 70.8333%;
    width: 70.8023%;
  }

  .pure-u-md-3-4,
  .pure-u-md-18-24 {
    width: 75%;
    width: 74.969%;
  }

  .pure-u-md-19-24 {
    width: 79.1667%;
    width: 79.1357%;
  }

  .pure-u-md-4-5 {
    width: 80%;
    width: 79.969%;
  }

  .pure-u-md-5-6,
  .pure-u-md-20-24 {
    width: 83.3333%;
    width: 83.3023%;
  }

  .pure-u-md-7-8,
  .pure-u-md-21-24 {
    width: 87.5%;
    width: 87.469%;
  }

  .pure-u-md-11-12,
  .pure-u-md-22-24 {
    width: 91.6667%;
    width: 91.6357%;
  }

  .pure-u-md-23-24 {
    width: 95.8333%;
    width: 95.8023%;
  }

  .pure-u-md-1,
  .pure-u-md-1-1,
  .pure-u-md-5-5,
  .pure-u-md-24-24 {
    width: 100%;
  }

  .hide-md {
    display: inherit;
  }
}

.hide-lg {
  display: none;
}

@media screen and (min-width: 64em) {
  .pure-u-lg-1,
  .pure-u-lg-1-1,
  .pure-u-lg-1-2,
  .pure-u-lg-1-3,
  .pure-u-lg-2-3,
  .pure-u-lg-1-4,
  .pure-u-lg-3-4,
  .pure-u-lg-1-5,
  .pure-u-lg-2-5,
  .pure-u-lg-3-5,
  .pure-u-lg-4-5,
  .pure-u-lg-5-5,
  .pure-u-lg-1-6,
  .pure-u-lg-5-6,
  .pure-u-lg-1-8,
  .pure-u-lg-3-8,
  .pure-u-lg-5-8,
  .pure-u-lg-7-8,
  .pure-u-lg-1-12,
  .pure-u-lg-5-12,
  .pure-u-lg-7-12,
  .pure-u-lg-11-12,
  .pure-u-lg-1-24,
  .pure-u-lg-2-24,
  .pure-u-lg-3-24,
  .pure-u-lg-4-24,
  .pure-u-lg-5-24,
  .pure-u-lg-6-24,
  .pure-u-lg-7-24,
  .pure-u-lg-8-24,
  .pure-u-lg-9-24,
  .pure-u-lg-10-24,
  .pure-u-lg-11-24,
  .pure-u-lg-12-24,
  .pure-u-lg-13-24,
  .pure-u-lg-14-24,
  .pure-u-lg-15-24,
  .pure-u-lg-16-24,
  .pure-u-lg-17-24,
  .pure-u-lg-18-24,
  .pure-u-lg-19-24,
  .pure-u-lg-20-24,
  .pure-u-lg-21-24,
  .pure-u-lg-22-24,
  .pure-u-lg-23-24,
  .pure-u-lg-24-24 {
    display: inline-block;
    display: inline;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
  }

  .pure-u-lg-1-24 {
    width: 4.1667%;
    width: 4.1357%;
  }

  .pure-u-lg-1-12,
  .pure-u-lg-2-24 {
    width: 8.3333%;
    width: 8.3023%;
  }

  .pure-u-lg-1-8,
  .pure-u-lg-3-24 {
    width: 12.5%;
    width: 12.469%;
  }

  .pure-u-lg-1-6,
  .pure-u-lg-4-24 {
    width: 16.6667%;
    width: 16.6357%;
  }

  .pure-u-lg-1-5 {
    width: 20%;
    width: 19.969%;
  }

  .pure-u-lg-5-24 {
    width: 20.8333%;
    width: 20.8023%;
  }

  .pure-u-lg-1-4,
  .pure-u-lg-6-24 {
    width: 25%;
    width: 24.969%;
  }

  .pure-u-lg-7-24 {
    width: 29.1667%;
    width: 29.1357%;
  }

  .pure-u-lg-1-3,
  .pure-u-lg-8-24 {
    width: 33.3333%;
    width: 33.3023%;
  }

  .pure-u-lg-3-8,
  .pure-u-lg-9-24 {
    width: 37.5%;
    width: 37.469%;
  }

  .pure-u-lg-2-5 {
    width: 40%;
    width: 39.969%;
  }

  .pure-u-lg-5-12,
  .pure-u-lg-10-24 {
    width: 41.6667%;
    width: 41.6357%;
  }

  .pure-u-lg-11-24 {
    width: 45.8333%;
    width: 45.8023%;
  }

  .pure-u-lg-1-2,
  .pure-u-lg-12-24 {
    width: 50%;
    width: 49.969%;
  }

  .pure-u-lg-13-24 {
    width: 54.1667%;
    width: 54.1357%;
  }

  .pure-u-lg-7-12,
  .pure-u-lg-14-24 {
    width: 58.3333%;
    width: 58.3023%;
  }

  .pure-u-lg-3-5 {
    width: 60%;
    width: 59.969%;
  }

  .pure-u-lg-5-8,
  .pure-u-lg-15-24 {
    width: 62.5%;
    width: 62.469%;
  }

  .pure-u-lg-2-3,
  .pure-u-lg-16-24 {
    width: 66.6667%;
    width: 66.6357%;
  }

  .pure-u-lg-17-24 {
    width: 70.8333%;
    width: 70.8023%;
  }

  .pure-u-lg-3-4,
  .pure-u-lg-18-24 {
    width: 75%;
    width: 74.969%;
  }

  .pure-u-lg-19-24 {
    width: 79.1667%;
    width: 79.1357%;
  }

  .pure-u-lg-4-5 {
    width: 80%;
    width: 79.969%;
  }

  .pure-u-lg-5-6,
  .pure-u-lg-20-24 {
    width: 83.3333%;
    width: 83.3023%;
  }

  .pure-u-lg-7-8,
  .pure-u-lg-21-24 {
    width: 87.5%;
    width: 87.469%;
  }

  .pure-u-lg-11-12,
  .pure-u-lg-22-24 {
    width: 91.6667%;
    width: 91.6357%;
  }

  .pure-u-lg-23-24 {
    width: 95.8333%;
    width: 95.8023%;
  }

  .pure-u-lg-1,
  .pure-u-lg-1-1,
  .pure-u-lg-5-5,
  .pure-u-lg-24-24 {
    width: 100%;
  }

  .hide-lg {
    display: inherit;
  }
}

.hide-xl {
  display: inherit;
}

@media screen and (min-width: 80em) {
  .pure-u-xl-1,
  .pure-u-xl-1-1,
  .pure-u-xl-1-2,
  .pure-u-xl-1-3,
  .pure-u-xl-2-3,
  .pure-u-xl-1-4,
  .pure-u-xl-3-4,
  .pure-u-xl-1-5,
  .pure-u-xl-2-5,
  .pure-u-xl-3-5,
  .pure-u-xl-4-5,
  .pure-u-xl-5-5,
  .pure-u-xl-1-6,
  .pure-u-xl-5-6,
  .pure-u-xl-1-8,
  .pure-u-xl-3-8,
  .pure-u-xl-5-8,
  .pure-u-xl-7-8,
  .pure-u-xl-1-12,
  .pure-u-xl-5-12,
  .pure-u-xl-7-12,
  .pure-u-xl-11-12,
  .pure-u-xl-1-24,
  .pure-u-xl-2-24,
  .pure-u-xl-3-24,
  .pure-u-xl-4-24,
  .pure-u-xl-5-24,
  .pure-u-xl-6-24,
  .pure-u-xl-7-24,
  .pure-u-xl-8-24,
  .pure-u-xl-9-24,
  .pure-u-xl-10-24,
  .pure-u-xl-11-24,
  .pure-u-xl-12-24,
  .pure-u-xl-13-24,
  .pure-u-xl-14-24,
  .pure-u-xl-15-24,
  .pure-u-xl-16-24,
  .pure-u-xl-17-24,
  .pure-u-xl-18-24,
  .pure-u-xl-19-24,
  .pure-u-xl-20-24,
  .pure-u-xl-21-24,
  .pure-u-xl-22-24,
  .pure-u-xl-23-24,
  .pure-u-xl-24-24 {
    display: inline-block;
    display: inline;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
  }

  .pure-u-xl-1-24 {
    width: 4.1667%;
    width: 4.1357%;
  }

  .pure-u-xl-1-12,
  .pure-u-xl-2-24 {
    width: 8.3333%;
    width: 8.3023%;
  }

  .pure-u-xl-1-8,
  .pure-u-xl-3-24 {
    width: 12.5%;
    width: 12.469%;
  }

  .pure-u-xl-1-6,
  .pure-u-xl-4-24 {
    width: 16.6667%;
    width: 16.6357%;
  }

  .pure-u-xl-1-5 {
    width: 20%;
    width: 19.969%;
  }

  .pure-u-xl-5-24 {
    width: 20.8333%;
    width: 20.8023%;
  }

  .pure-u-xl-1-4,
  .pure-u-xl-6-24 {
    width: 25%;
    width: 24.969%;
  }

  .pure-u-xl-7-24 {
    width: 29.1667%;
    width: 29.1357%;
  }

  .pure-u-xl-1-3,
  .pure-u-xl-8-24 {
    width: 33.3333%;
    width: 33.3023%;
  }

  .pure-u-xl-3-8,
  .pure-u-xl-9-24 {
    width: 37.5%;
    width: 37.469%;
  }

  .pure-u-xl-2-5 {
    width: 40%;
    width: 39.969%;
  }

  .pure-u-xl-5-12,
  .pure-u-xl-10-24 {
    width: 41.6667%;
    width: 41.6357%;
  }

  .pure-u-xl-11-24 {
    width: 45.8333%;
    width: 45.8023%;
  }

  .pure-u-xl-1-2,
  .pure-u-xl-12-24 {
    width: 50%;
    width: 49.969%;
  }

  .pure-u-xl-13-24 {
    width: 54.1667%;
    width: 54.1357%;
  }

  .pure-u-xl-7-12,
  .pure-u-xl-14-24 {
    width: 58.3333%;
    width: 58.3023%;
  }

  .pure-u-xl-3-5 {
    width: 60%;
    width: 59.969%;
  }

  .pure-u-xl-5-8,
  .pure-u-xl-15-24 {
    width: 62.5%;
    width: 62.469%;
  }

  .pure-u-xl-2-3,
  .pure-u-xl-16-24 {
    width: 66.6667%;
    width: 66.6357%;
  }

  .pure-u-xl-17-24 {
    width: 70.8333%;
    width: 70.8023%;
  }

  .pure-u-xl-3-4,
  .pure-u-xl-18-24 {
    width: 75%;
    width: 74.969%;
  }

  .pure-u-xl-19-24 {
    width: 79.1667%;
    width: 79.1357%;
  }

  .pure-u-xl-4-5 {
    width: 80%;
    width: 79.969%;
  }

  .pure-u-xl-5-6,
  .pure-u-xl-20-24 {
    width: 83.3333%;
    width: 83.3023%;
  }

  .pure-u-xl-7-8,
  .pure-u-xl-21-24 {
    width: 87.5%;
    width: 87.469%;
  }

  .pure-u-xl-11-12,
  .pure-u-xl-22-24 {
    width: 91.6667%;
    width: 91.6357%;
  }

  .pure-u-xl-23-24 {
    width: 95.8333%;
    width: 95.8023%;
  }

  .pure-u-xl-1,
  .pure-u-xl-1-1,
  .pure-u-xl-5-5,
  .pure-u-xl-24-24 {
    width: 100%;
  }

  .hide-xl {
    display: inherit;
  }
}

.query-feedback-container {
  display: inline-block;
  float: right;
}

.query-feedback-container a.query-feedback-thumb {
  display: inline-block;
  margin-left: 8px;
  color: #888;
}

.query-feedback-container a.query-feedback-thumb:hover {
  color: #044ea0;
}

.query-feedback-container a.query-feedback-thumb:active,
.query-feedback-container a.query-feedback-thumb.query-feedback-thumb-active {
  color: #044ea0;
}

:root {
  --react-pdf-annotation-layer: 1;
  --annotation-unfocused-field-background: url("data:image/svg+xml;charset=UTF-8,<svg width='1px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill:rgba(0, 54, 255, 0.13);'/></svg>");
  --input-focus-border-color: Highlight;
  --input-focus-outline: 1px solid Canvas;
  --input-unfocused-border-color: transparent;
  --input-disabled-border-color: transparent;
  --input-hover-border-color: black;
  --link-outline: none;
}

@media screen and (forced-colors: active) {
  :root {
    --input-focus-border-color: CanvasText;
    --input-unfocused-border-color: ActiveText;
    --input-disabled-border-color: GrayText;
    --input-hover-border-color: Highlight;
    --link-outline: 1.5px solid LinkText;
  }

  .annotationLayer .textWidgetAnnotation :is(input, textarea):required,
  .annotationLayer .choiceWidgetAnnotation select:required,
  .annotationLayer
    .buttonWidgetAnnotation:is(.checkBox, .radioButton)
    input:required {
    outline: 1.5px solid selectedItem;
  }

  .annotationLayer .linkAnnotation:hover {
    backdrop-filter: invert(100%);
  }
}

.annotationLayer {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  transform-origin: 0 0;
  z-index: 3;
}

.annotationLayer[data-main-rotation="90"] .norotate {
  transform: rotate(270deg) translateX(-100%);
}

.annotationLayer[data-main-rotation="180"] .norotate {
  transform: rotate(180deg) translate(-100%, -100%);
}

.annotationLayer[data-main-rotation="270"] .norotate {
  transform: rotate(90deg) translateY(-100%);
}

.annotationLayer canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

.annotationLayer section {
  position: absolute;
  text-align: initial;
  pointer-events: auto;
  box-sizing: border-box;
  margin: 0;
  transform-origin: 0 0;
}

.annotationLayer .linkAnnotation {
  outline: var(--link-outline);
}

.annotationLayer :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton) > a {
  position: absolute;
  font-size: 1em;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.annotationLayer
  :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton)
  > a:hover {
  opacity: 0.2;
  background: yellow;
  box-shadow: 0 2px 10px yellow;
}

.annotationLayer .textAnnotation img {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea),
.annotationLayer .choiceWidgetAnnotation select,
.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input {
  background-image: var(--annotation-unfocused-field-background);
  border: 2px solid var(--input-unfocused-border-color);
  box-sizing: border-box;
  font: calc(9px * var(--scale-factor)) sans-serif;
  height: 100%;
  margin: 0;
  vertical-align: top;
  width: 100%;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):required,
.annotationLayer .choiceWidgetAnnotation select:required,
.annotationLayer
  .buttonWidgetAnnotation:is(.checkBox, .radioButton)
  input:required {
  outline: 1.5px solid red;
}

.annotationLayer .choiceWidgetAnnotation select option {
  padding: 0;
}

.annotationLayer .buttonWidgetAnnotation.radioButton input {
  border-radius: 50%;
}

.annotationLayer .textWidgetAnnotation textarea {
  resize: none;
}

.annotationLayer .textWidgetAnnotation [disabled]:is(input, textarea),
.annotationLayer .choiceWidgetAnnotation select[disabled],
.annotationLayer
  .buttonWidgetAnnotation:is(.checkBox, .radioButton)
  input[disabled] {
  background: none;
  border: 2px solid var(--input-disabled-border-color);
  cursor: not-allowed;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):hover,
.annotationLayer .choiceWidgetAnnotation select:hover,
.annotationLayer
  .buttonWidgetAnnotation:is(.checkBox, .radioButton)
  input:hover {
  border: 2px solid var(--input-hover-border-color);
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):hover,
.annotationLayer .choiceWidgetAnnotation select:hover,
.annotationLayer .buttonWidgetAnnotation.checkBox input:hover {
  border-radius: 2px;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):focus,
.annotationLayer .choiceWidgetAnnotation select:focus {
  background: none;
  border: 2px solid var(--input-focus-border-color);
  border-radius: 2px;
  outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) :focus {
  background-image: none;
  background-color: transparent;
}

.annotationLayer .buttonWidgetAnnotation.checkBox :focus {
  border: 2px solid var(--input-focus-border-color);
  border-radius: 2px;
  outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation.radioButton :focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before,
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after,
.annotationLayer .buttonWidgetAnnotation.radioButton input:checked::before {
  background-color: CanvasText;
  content: "";
  display: block;
  position: absolute;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before,
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after {
  height: 80%;
  left: 45%;
  width: 1px;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before {
  transform: rotate(45deg);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after {
  transform: rotate(-45deg);
}

.annotationLayer .buttonWidgetAnnotation.radioButton input:checked::before {
  border-radius: 50%;
  height: 50%;
  left: 30%;
  top: 20%;
  width: 50%;
}

.annotationLayer .textWidgetAnnotation input.comb {
  font-family: monospace;
  padding-left: 2px;
  padding-right: 0;
}

.annotationLayer .textWidgetAnnotation input.comb:focus {
  width: 103%;
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input {
  appearance: none;
}

.annotationLayer .popupTriggerArea {
  height: 100%;
  width: 100%;
}

.annotationLayer .fileAttachmentAnnotation .popupTriggerArea {
  position: absolute;
}

.annotationLayer .popupWrapper {
  position: absolute;
  font-size: calc(9px * var(--scale-factor));
  width: 100%;
  min-width: calc(180px * var(--scale-factor));
  pointer-events: none;
}

.annotationLayer .popup {
  position: absolute;
  max-width: calc(180px * var(--scale-factor));
  background-color: #ffff99;
  box-shadow: 0 calc(2px * var(--scale-factor)) calc(5px * var(--scale-factor))
    #888;
  border-radius: calc(2px * var(--scale-factor));
  padding: calc(6px * var(--scale-factor));
  margin-left: calc(5px * var(--scale-factor));
  cursor: pointer;
  font: message-box;
  white-space: normal;
  word-wrap: break-word;
  pointer-events: auto;
}

.annotationLayer .popup > * {
  font-size: calc(9px * var(--scale-factor));
}

.annotationLayer .popup h1 {
  display: inline-block;
}

.annotationLayer .popupDate {
  display: inline-block;
  margin-left: calc(5px * var(--scale-factor));
}

.annotationLayer .popupContent {
  border-top: 1px solid #333333;
  margin-top: calc(2px * var(--scale-factor));
  padding-top: calc(2px * var(--scale-factor));
}

.annotationLayer .richText > * {
  white-space: pre-wrap;
  font-size: calc(9px * var(--scale-factor));
}

.annotationLayer .highlightAnnotation,
.annotationLayer .underlineAnnotation,
.annotationLayer .squigglyAnnotation,
.annotationLayer .strikeoutAnnotation,
.annotationLayer .freeTextAnnotation,
.annotationLayer .lineAnnotation svg line,
.annotationLayer .squareAnnotation svg rect,
.annotationLayer .circleAnnotation svg ellipse,
.annotationLayer .polylineAnnotation svg polyline,
.annotationLayer .polygonAnnotation svg polygon,
.annotationLayer .caretAnnotation,
.annotationLayer .inkAnnotation svg polyline,
.annotationLayer .stampAnnotation,
.annotationLayer .fileAttachmentAnnotation {
  cursor: pointer;
}

.annotationLayer section svg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.annotationLayer .annotationTextContent {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: transparent;
  user-select: none;
  pointer-events: none;
}

.annotationLayer .annotationTextContent span {
  width: 100%;
  display: inline-block;
}

:root {
  --react-pdf-text-layer: 1;
  --highlight-bg-color: rgba(180, 0, 170, 1);
  --highlight-selected-bg-color: rgba(0, 100, 0, 1);
}

@media screen and (forced-colors: active) {
  :root {
    --highlight-bg-color: Highlight;
    --highlight-selected-bg-color: ButtonText;
  }
}

[data-main-rotation="90"] {
  transform: rotate(90deg) translateY(-100%);
}

[data-main-rotation="180"] {
  transform: rotate(180deg) translate(-100%, -100%);
}

[data-main-rotation="270"] {
  transform: rotate(270deg) translateX(-100%);
}

.textLayer {
  position: absolute;
  text-align: initial;
  inset: 0;
  overflow: hidden;
  line-height: 1;
  text-size-adjust: none;
  forced-color-adjust: none;
  transform-origin: 0 0;
  z-index: 2;
}

.textLayer :is(span, br) {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  margin: 0;
  transform-origin: 0 0;
}

.textLayer span.markedContent {
  top: 0;
  height: 0;
}

.textLayer .highlight {
  margin: -1px;
  padding: 1px;
  background-color: var(--highlight-bg-color);
  border-radius: 4px;
}

.textLayer .highlight.appended {
  position: initial;
}

.textLayer .highlight.begin {
  border-radius: 4px 0 0 4px;
}

.textLayer .highlight.end {
  border-radius: 0 4px 4px 0;
}

.textLayer .highlight.middle {
  border-radius: 0;
}

.textLayer .highlight.selected {
  background-color: var(--highlight-selected-bg-color);
}

.textLayer br::selection {
  background: transparent;
}

.textLayer .endOfContent {
  display: block;
  position: absolute;
  inset: 100% 0 0;
  z-index: -1;
  cursor: default;
  user-select: none;
}

.textLayer .endOfContent.active {
  top: 0;
}

.referred-by-user-notification {
  background-color: #2dbb51;
  font-weight: 600;
  text-align: center;
  font-size: 16px;
  padding: 18px 0;
  width: 100%;
  color: #fff;
}

.social-button {
  /* background-image: url(/assets/share_sprite-9e766a8f48914720ee0572107a80e3541ac1a2e6b547e023c044b50525393f2d.png); */
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 1px;
  height: 25px;
  width: 68px;
}

.social-button.facebook-social-button {
  background-position: 0px 0px;
}

.social-button.facebook-social-button:hover:not(:active) {
  background-position: 0px -35px;
}

.social-button.email-social-button {
  background-position: 0px -70px;
  width: 56px;
}

.social-button.email-social-button:hover:not(:active) {
  background-position: 0px -105px;
}

.social-button.twitter-social-button {
  background-position: -1px -140px;
}

.social-button.twitter-social-button:hover:not(:active) {
  background-position: -1px -175px;
}

.social-button.linkedin-social-button {
  background-position: 0px -210px;
}

.social-button.linkedin-social-button:hover:not(:active) {
  background-position: 0px -246px;
}

#rename_api_key_modal input[name="name"] {
  width: 96%;
}

#rename_file_or_directory_modal input[name="name"] {
  width: 96%;
}

textarea.prompt-modal-prompt-textarea {
  resize: vertical;
  width: 96%;
}

.schedule h4 {
  margin-top: 7px;
}

.semiblank-width {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 48em) {
  .semiblank-width {
    width: 360px;
  }
}

#whole-np.semiblank {
  background-color: #044ea0;
  min-height: 100%;
  position: relative;
}

@media screen and (max-width: 48em) {
  #whole-np.semiblank input:not([type="submit"]):not([type="checkbox"]) {
    width: 90%;
  }
}

#semiblank_header {
  font-size: 21px;
  padding-top: 52px;
  padding-bottom: 18px;
  text-align: center;
}

#semiblank_header a {
  color: #fff;
}

#semiblank_header a:hover {
  color: #0093e6;
}

#semiblank_header a:active {
  color: #044ea0;
}

#semiblank_header .title:hover {
  text-decoration: none;
}

#semiblank_before_title {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 11px;
  margin-bottom: 8px;
}

#semiblank_content {
  background-color: #fff;
  border-radius: 8px;
  color: #020202;
  margin-left: auto;
  margin-right: auto;
  width: 600px;
}

@media screen and (max-width: 48em) {
  #semiblank_content {
    width: 360px;
  }
}

#semiblank_content_inside {
  padding: 20px;
}

.semiblank-main-title {
  margin-bottom: 5px;
}

#semiblank_footer {
  text-align: center;
  font-size: 11px;
  color: #fff;
  margin-top: 6px;
}

.settings-navigation {
  margin-top: 24px;
  font-size: 14px;
}

.settings-navigation .settings-navigation-link {
  color: #044ea0;
  display: block;
  margin-bottom: 5px;
}

.settings-navigation .settings-navigation-link.selected {
  color: #202020;
  cursor: default;
  font-weight: bold;
}

.settings-navigation .settings-navigation-link.selected:hover,
.settings-navigation .settings-navigation-link.selected:active {
  text-decoration: none;
}

.share-menu {
  position: absolute;
  max-width: 300px;
  z-index: 8000;
  border: 1px solid #ccc;
  border-bottom: 0px;
}

.share-menu .share-menu-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#d9d9d9)
  );
  background: -moz-linear-gradient(top, #fff, #d9d9d9);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF', endColorstr='#D9D9D9');
  border-bottom: 1px solid #ccc;
  display: block;
  position: relative;
  padding: 3px 5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  width: 120px;
}

.share-menu .share-menu-item:hover {
  background-color: #0565dc;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#0565dc),
    to(#044b99)
  );
  background: -moz-linear-gradient(top, #0565dc, #044b99);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0565DC', endColorstr='#044B99');
  color: #fff;
}

.share-menu .share-menu-item:active {
  color: #009ce6;
  background-color: #eee;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#eee),
    to(silver)
  );
  background: -moz-linear-gradient(top, #eee, silver);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EEE', endColorstr='#C0C0C0');
}

.share-menu .share-menu-item.disabled,
.share-menu .share-menu-item.disabled:hover,
.share-menu .share-menu-item.disabled:active {
  background-color: #d0d0d0;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#d0d0d0),
    to(#aaa)
  );
  background: -moz-linear-gradient(top, #d0d0d0, #aaa);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D0D0D0', endColorstr='#AAA');
  border-color: #aaa;
  color: #555;
  cursor: default;
}

#share_object_modal .checkbox-container {
  display: inline-block;
}

#share_object_modal label {
  display: inline;
  margin-left: 4px;
}

.side-blurb {
  border-radius: 4px;
  border: 1px solid #044ea0;
  font-size: 83%;
  margin: 30px 50px;
  padding: 12px 12px 9px;
  color: #044ea0;
}

.side-blurb p {
  margin-top: 6px;
  font-size: 12px;
  line-height: 13px;
  text-align: justify;
  font-weight: 400;
}

.side-blurb h2 {
  text-align: center;
}

#static_about_page #about_us_section {
  background-color: #034287;
  display: block;
  position: relative;
  overflow: hidden;
  min-height: 320px;
  padding-bottom: 60px;
}

#static_about_page #about_us_section #about_us_section_interior {
  display: block;
  position: relative;
}

#static_about_page #about_us_section img#about_us_image {
  position: absolute;
  top: 0;
  right: 0;
  width: 83%;
  z-index: 10;
}

@media screen and (max-width: 35.5em) {
  #static_about_page #about_us_section img#about_us_image {
    width: 99%;
    right: 30px;
    top: 140px;
  }
}

#static_about_page #about_us_section #about_us_info {
  color: #fff;
  max-width: 310px;
  font-size: 17px;
  text-align: left;
  float: left;
  margin-top: 80px;
  line-height: 25px;
  z-index: 20;
  display: block;
  position: relative;
}

#static_about_page #about_us_section #about_us_info h2 {
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 20px;
}

@media screen and (max-width: 35.5em) {
  #static_about_page #about_us_section #about_us_info {
    text-align: center;
    float: none;
    margin-top: 30px;
    max-width: 90%;
  }
}

#static_about_page #vision_section {
  min-height: 240px;
  display: block;
  position: relative;
  padding: 0;
}

#static_about_page #vision_section #targets_img {
  display: block;
  float: left;
  width: 45%;
}

@media screen and (max-width: 35.5em) {
  #static_about_page #vision_section #targets_img {
    text-align: center;
    float: none;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

#static_about_page #vision_section #vision_section_info {
  display: block;
  width: 45%;
  float: right;
  font-size: 17px;
  line-height: 25px;
  padding-top: 100px;
}

@media screen and (max-width: 35.5em) {
  #static_about_page #vision_section #vision_section_info {
    text-align: center;
    float: none;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 30px 0;
  }
}

#static_about_page #vision_section #vision_section_info h2 {
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 20px;
}

#static_about_page .diagonal-border {
  max-height: 80px;
  width: 100%;
  position: absolute;
  z-index: 1700;
  left: 50%;
  transform: translateX(-50%);
}

#static_about_page .diagonal-border-bottom {
  top: 99%;
}

#static_about_page .diagonal-border-top {
  bottom: 99%;
}

#static_about_page #founder_section {
  background-color: #0093e6;
  display: block;
  position: relative;
  overflow: hidden;
  min-height: 220px;
  padding: 90px 0 100px;
}

@media screen and (max-width: 35.5em) {
  #static_about_page #founder_section {
    padding-top: 50px;
    padding-bottom: 40px;
  }
}

#static_about_page #founder_section #founder_image_container {
  border-radius: 50%;
  object-fit: cover;
  width: 28%;
  float: right;
  margin-right: 60px;
  margin-top: 30px;
}

#static_about_page
  #founder_section
  #founder_image_container
  > img#founder_image {
  border-radius: 50%;
  object-fit: cover;
  width: 100%;
}

@media screen and (max-width: 35.5em) {
  #static_about_page
    #founder_section
    #founder_image_container
    > img#founder_image {
    display: none;
  }
}

#static_about_page #founder_section #founder_image_container_mobile {
  display: none;
  border-radius: 50%;
  object-fit: cover;
  width: 180px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

@media screen and (max-width: 35.5em) {
  #static_about_page #founder_section #founder_image_container_mobile {
    display: block;
  }
}

#static_about_page
  #founder_section
  #founder_image_container_mobile
  > img#founder_image_mobile {
  border-radius: 50%;
  object-fit: cover;
  width: 100%;
}

#static_about_page #founder_section #founder_section_info {
  float: left;
  width: 47%;
  color: #fff;
  font-size: 17px;
  line-height: 25px;
  padding-top: 20px;
}

@media screen and (max-width: 35.5em) {
  #static_about_page #founder_section #founder_section_info {
    float: none;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

#static_about_page #founder_section #founder_section_info h2 {
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 20px;
}

#static_contact_page form#contact_form {
  text-align: left;
  margin-left: 12px;
  line-height: 32px;
  margin-bottom: 30px;
}

#static_contact_page form#contact_form.error {
  border: 3px solid #bb1c19;
}

#static_contact_page form#contact_form img {
  margin-left: auto;
  margin-right: auto;
}

#static_contact_page form#contact_form #contact_thinking {
  display: none;
  margin-bottom: 42px;
  margin-top: 42px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}

#static_contact_page form#contact_form.submitting #contact_main_form {
  display: none;
}

#static_contact_page form#contact_form.submitting #contact_thinking {
  display: block;
  text-align: center;
}

#static_contact_page form#contact_form #contact_received,
#static_contact_page form#contact_form #contact_error {
  display: none;
  font-weight: bold;
  font-size: 120%;
  margin-bottom: 30px;
  margin-top: 30px;
  text-align: center;
}

#static_contact_page form#contact_form #contact_received {
  color: #2cab49;
}

#static_contact_page form#contact_form #contact_error {
  color: #bb1c19;
}

#static_contact_page form#contact_form.error form {
  display: none;
}

#static_contact_page form#contact_form.error #contact_error {
  display: block;
}

#static_contact_page form#contact_form.success #contact_main_form {
  display: none;
}

#static_contact_page form#contact_form.success #contact_received {
  display: block;
}

#static_contact_page form#contact_form input[type="text"] {
  min-width: 220px;
}

#static_contact_page form#contact_form #form_message {
  width: 100%;
}

@media screen and (max-width: 35.5em) {
  #static_contact_page form#contact_form input[type="text"] {
    min-width: 200px;
  }

  #static_contact_page #form_message {
    max-width: 91%;
    margin-left: auto;
    margin-right: auto;
  }

  #static_contact_page #contact_submit_container {
    text-align: center;
  }

  #static_contact_page a#contact_submit {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
  }
}

#stored_files_all_page #show_new_file_modal_button,
#stored_files_all_page #new_folder_button {
  display: inline-block;
  float: right;
  margin-top: 10px;
  margin-left: 13px;
}

@media screen and (max-width: 35.5em) {
  #stored_files_all_page #show_new_file_modal_button,
  #stored_files_all_page #new_folder_button {
    margin-top: 10px;
  }
}

#stored_files_all_page #your_files_header {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  display: inline-block;
}

#stored_files_embed_page .settings-label {
  display: inline;
  margin-left: 4px;
}

#stored_files_embed_page .checkbox-container {
  display: inline-block;
}

.files-list-object-icon {
  color: #bbb;
  font-size: 15px;
}

.files-list-title-path {
  color: #777;
  display: inline-block;
  max-width: 600px;
}

@media screen and (max-width: 35.5em) {
  .files-list-title-path {
    max-width: 140px;
  }
}

.files-list-title-directory {
  cursor: pointer;
  padding: 4px;
}

.files-list-title-directory:hover {
  color: #222;
}

.files-list-title-directory-dropping {
  background-color: #f1df67;
}

.files-list-title-directory-last {
  color: #222;
  cursor: default;
}

.files-list-title-path-separator {
  margin-left: 5px;
  margin-right: 5px;
}

.files-list-empty {
  padding: 7px;
}

.stored-files-list .files-list-row-name {
  text-align: left;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 300px;
  flex: 10;
  white-space: nowrap;
}

@media screen and (max-width: 35.5em) {
  .stored-files-list .files-list-row-name {
    min-width: 130px;
  }
}

.stored-files-list .files-list-row-created-at {
  text-align: right;
  display: inline-block;
  flex: 6;
}

.stored-files-list .files-list-row-file-size {
  text-align: right;
  display: inline-block;
  flex: 5;
}

.stored-files-list .files-list-row-page-count {
  text-align: right;
  display: inline-block;
  flex: 2;
}

#after_files_list_container {
  margin-top: 5px;
}

#stored_files_show_page {
  position: relative;
}

#stored_files_show_page #pdf_outer_container {
  background-color: #333639;
  display: block;
  position: relative;
  height: calc(100vh - 56px);
}

#stored_files_show_page #pdf_outer_container.no-page-header {
  height: 100vh;
}

#stored_files_show_page #pdf_outer_container.not-pdf-container {
  border-right: 2px solid #333639;
  color: #111;
}

#stored_files_show_page #pdf_outer_container.not-pdf-container #pdf_container {
  background-color: #fff;
}

#stored_files_show_page #pdf_outer_container #stored_file_as_txt,
#stored_files_show_page #pdf_outer_container #stored_file_as_ms_doc {
  border-top: 56px solid #333639;
  color: #111;
  height: calc(100vh - 122px);
}

#stored_files_show_page #pdf_outer_container #stored_file_as_ms_doc > iframe {
  width: 100%;
  height: 100%;
}

#stored_files_show_page #pdf_outer_container #stored_file_as_csv_table {
  border-top: 56px solid #333639;
  padding: 4px;
  color: #111;
  height: calc(100vh - 130px);
  overflow-y: auto;
}

#stored_files_show_page #pdf_outer_container #stored_file_as_csv_table th,
#stored_files_show_page #pdf_outer_container #stored_file_as_csv_table td {
  border: 1px solid #333639;
  padding: 5px;
}

#stored_files_show_page #pdf_container {
  color: #ffffff;
  height: 99%;
}

.styled-list {
  border-bottom: 1px solid #044ea0;
  border-left: 1px solid #044ea0;
  border-right: 1px solid #044ea0;
  border-radius: 5px;
}

.styled-list-row {
  border-top: 1px solid #96b0fe;
  font-size: 13px;
  line-height: 26px;
  padding: 2px 0px 2px 8px;
  display: flex;
  flex-wrap: nowrap;
}

.styled-list-row:first-child {
  border-top: 0;
}

.styled-list-row.odd {
  background-color: #f7f7fc;
}

.styled-list-row.styled-list-row-header {
  background-color: #044ea0;
  color: #fefefe;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.styled-list-row:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.styled-list-row:not(.styled-list-row-header):not(
    .styled-list-row-unselectable
  ) {
  cursor: pointer;
}

.styled-list-row:not(.styled-list-row-header):not(
    .styled-list-row-unselectable
  ):hover {
  background-color: #f1f1f1;
}

.styled-list-row.dragging {
  background-color: #fefefe !important;
}

.styled-list-row.dragging.odd {
  background-color: #f7f7fc !important;
}

.styled-list-row.dropping {
  background-color: #f1df67 !important;
}

.styled-list-row .styled-list-row-actions-container {
  text-align: center;
  display: inline-block;
  width: 60px;
}

.styled-list-row .styled-list-row-actions-dots-container {
  border-radius: 14px;
  cursor: pointer;
  width: 28px;
  height: 28px;
  display: inline-block;
  text-align: center;
}

.styled-list-row .styled-list-row-actions-dots-container img {
  margin-top: 8px;
}

.styled-list-row
  .styled-list-row-actions-dots-container:hover:not(.styled-list-header-dots) {
  background-color: #ccc;
}

.styled-list-operations-menu {
  position: absolute;
  max-width: 300px;
  z-index: 8000;
  border: 1px solid #ccc;
  border-bottom: 0px;
}

.styled-list-operations-menu .styled-list-operations-menu-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#d9d9d9)
  );
  background: -moz-linear-gradient(top, #fff, #d9d9d9);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF', endColorstr='#D9D9D9');
  border-bottom: 1px solid #ccc;
  display: block;
  position: relative;
  padding: 4px 10px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.styled-list-operations-menu .styled-list-operations-menu-item:hover {
  background-color: #009cee;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#009cee),
    to(#0048c0)
  );
  background: -moz-linear-gradient(top, #009cee, #0048c0);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#009CEE', endColorstr='#0048C0');
  color: #fff;
}

.styled-list-operations-menu .styled-list-operations-menu-item:active {
  color: #009ce6;
  background-color: #eee;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#eee),
    to(silver)
  );
  background: -moz-linear-gradient(top, #eee, silver);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EEE', endColorstr='#C0C0C0');
}

.styled-list-operations-menu .styled-list-operations-menu-item.disabled,
.styled-list-operations-menu .styled-list-operations-menu-item.disabled:hover,
.styled-list-operations-menu .styled-list-operations-menu-item.disabled:active {
  background-color: #d0d0d0;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#d0d0d0),
    to(#aaa)
  );
  background: -moz-linear-gradient(top, #d0d0d0, #aaa);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D0D0D0', endColorstr='#AAA');
  border-color: #aaa;
  color: #555;
  cursor: default;
}

.styled-operations-menu {
  position: absolute;
  max-width: 300px;
  z-index: 8000;
  border: 1px solid #ccc;
  border-bottom: 0px;
}

.styled-operations-menu .styled-operations-menu-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  /* background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#D9D9D9)); */
  background: -moz-linear-gradient(top, #fff, #d9d9d9);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF', endColorstr='#D9D9D9');
  border-bottom: 1px solid #ccc;
  display: block;
  position: relative;
  padding: 4px 10px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.styled-operations-menu .styled-operations-menu-item:hover {
  background-color: #009cee;
  /* background: -webkit-gradient(linear, left top, left bottom, from(#009CEE), to(#0048C0)); */
  background: -moz-linear-gradient(top, #009cee, #0048c0);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#009CEE', endColorstr='#0048C0');
  color: #fff;
}

.styled-operations-menu .styled-operations-menu-item:active {
  color: #009ce6;
  background-color: #eee;
  /* background: -webkit-gradient(linear, left top, left bottom, from(#EEE), to(silver)); */
  background: -moz-linear-gradient(top, #eee, silver);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EEE', endColorstr='#C0C0C0');
}

.styled-operations-menu .styled-operations-menu-item.disabled,
.styled-operations-menu .styled-operations-menu-item.disabled:hover,
.styled-operations-menu .styled-operations-menu-item.disabled:active {
  background-color: #d0d0d0;
  /* background: -webkit-gradient(linear, left top, left bottom, from(#D0D0D0), to(#AAA)); */
  background: -moz-linear-gradient(top, #d0d0d0, #aaa);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D0D0D0', endColorstr='#AAA');
  border-color: #aaa;
  color: #555;
  cursor: default;
}

.switchable-noneditable {
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-block;
  padding: 4px 0;
}

.switchable-noneditable:hover {
  background-color: #fff;
  border: 1px solid #e0e0e0;
}

.switchable-noneditable.switchable-placeholder {
  font-style: italic;
}

.team-dashboard-page-header-name {
  font-size: 22px;
  margin-top: 9px;
}

.team-dashboard-page-header-name .switchable-noneditable {
  padding: 4px;
}

.team-dashboard-page-header-name input.switchable-editable[type="text"] {
  width: auto;
}

#team_members_list_container {
  padding: 6px;
}

#your_team_users {
  font-size: 16px;
  margin: 8px 0;
}

.team-member-list .team-member-list-row-name {
  flex: 2;
}

.team-member-list .team-member-list-row-email {
  flex: 2;
}

.team-member-list .team-member-list-row-role {
  flex: 2;
}

.team-member-list-actions {
  float: right;
  position: relative;
  top: -5px;
}

.team-member-list-actions .button {
  padding: 5px 10px;
  font-size: 13px;
  float: right;
}

#teams_user_details_page #teams_user_details_header {
  font-size: 22px;
  margin-top: 12px;
}

#teams_user_details_page #teams_user_details_user_sentence {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
}

#teams_user_details_page #your_files_header {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  display: inline-block;
}

.usage-bar {
  width: 400px;
  height: 22px;
  border: 1px solid #dedede;
  background-color: #fafafa;
  position: relative;
  display: block;
}

.usage-bar-inner {
  height: 100%;
  background-color: #f1df67;
  position: absolute;
  left: 0;
}

.usage-bar-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: #222;
}

#users_credits_page h3.section-header {
  margin-bottom: 10px;
}

#users_credits_page .side-blurb .referral-url-container {
  font-size: 10px;
  margin-top: 9px;
  margin-bottom: 9px;
  text-align: center;
}

#users_credits_page .side-blurb .youve-referred {
  text-align: center;
  font-size: 10px;
}

#users_credits_page .side-blurb .social-buttons {
  text-align: center;
}

#users_plan_page .my-plan-info {
  margin-bottom: 30px;
}

#users_plan_page .my-plan-info p {
  margin-bottom: 7px;
  font-size: 13px;
}

#users_settings_page #edit_user_form .profile-pic-container {
  margin: 2px 0 6px 2px;
}

#users_settings_page
  #edit_user_form
  .profile-pic-container
  .current-user-profile-pic {
  border-radius: 50%;
  cursor: pointer;
}

#users_settings_page #edit_user_form .birthday-selector {
  margin-bottom: 12px;
}

#users_settings_page #edit_user_form .link-input {
  padding: 3px 12px;
  font-size: 13px;
}

#users_settings_page #edit_user_form .checkbox-container-below-input {
  margin-top: 5px;
}

#users_settings_page #edit_user_form .settings-label {
  display: inline;
  margin-left: 4px;
}

#users_settings_page #edit_user_form .checkbox-container {
  display: inline-block;
}

#users_team_page p {
  font-size: 13px;
}

.benefit-image > img {
  height: 100px;
  width: 150px;
  object-fit: fill;
}

/* Css By NP */
.np-menu-new {
  display: none;
}

@media screen and (max-width: 700px) {
  .fixed-width {
    gap: 0;
  }
  .np-menu-new {
    display: block;
    cursor: pointer;
  }
  .np-menu {
    display: none !important;
  }
  .menu-items {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    left: 0;
    /* width: calc(100% - 2rem); */
    width: 100%;
    background-color: #fff;
    top: 70px;
    color: black;
    font-size: 18px;
    padding: 1rem;
  }

  .menu-items > div {
    cursor: pointer;
  }
  .np-2 {
    flex-direction: column-reverse;
  }
  #top_section_text {
    flex-direction: column;
    width: 80% !important;
    padding: 1rem 0 !important;
  }
  #try_now_button {
    padding-left: 1.5rem;
  }
  .np-ben {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  .np-ben > div {
    width: 70% !important;
  }
  .np-ben > div > div > img {
    width: 80%;
    height: auto;
  }
  .why-this {
    width: 100% !important;
    max-width: 100% !important;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .why_section_part2 {
    display: block !important;
  }
  #static_index_page #why_section {
    padding: 2rem 0;
  }
  #top_section_text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  #top_section_image_mobile,
  #why_section_diagram {
    width: 200px;
    height: 150px;
    object-fit: fill;
  }
  #static_index_page #why_section #why_section_diagram {
    margin: 0;
  }
  .right {
    display: flex;
  }
}

@media screen and (max-width: 500px) {
  .footer-sd {
    display: flex !important;
    /* flex-wrap: wrap !important; */
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
  #footer {
    height: 100%;
  }
  .right div {
    margin: 0;
  }
  #static_pricing_page #plans {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 290px;
    gap: 2rem;
  }
  #static_pricing_page .quote-container {
    width: 250px;
  }
  #static_pricing_page .plan {
    width: 80%;
  }
  #static_pricing_page .plan .benefits {
    min-height: auto;
  }
  .np_01 {
    gap: 0rem !important;
  }
  #targets_img {
    display: none !important;
  }
  #founder_image_container {
    display: none !important;
  }
}
.np_01 {
  gap: 0 !important;
}
