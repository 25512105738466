.price_cards{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: space-between;
}
.container {
    width: 380px;
    padding: 40px;
    background: #ffffff;
    text-align: center;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    position: relative;
}
.container .title {
    font-size: 1.5rem;
    color: #333;
}
.container .price {
    color: blue;
    font-weight: 700;
    font-size: 1.65rem;
    margin: 15px 0;
}
.container span {
    font-size: 1.2rem;
}
.container .description {
    color: #3b3b3b;
    font-size: 1.1rem;
    margin: 20px 0 20px;
}
.container .offer {
    display: block;
    color: #555;
    font-size: 1rem;
    margin-top: 25px;
}
.subscribe_button {
    display: inline-block;
    padding: 15px 0;
    background-color: blue;
    color: #fff;
    text-decoration: none;
    border-radius: 30px;
    font-size: 1.2rem;
    margin-top: 40px;
    width: 100%;
    font-weight: 500;
    transition: 0.2s ease;
}
.subscribe_button:hover {
    background: #ff4d4d;
}
.ribbon_wrap {
    width: 150px;
    height: 150px;
    position: absolute;
    top: -10px;
    left: -10px;
    pointer-events: none;
}
.ribbon {
    width: 230px;
    font-size: 0.918rem;
    text-align: center;
    padding: 8px 0;
    background: blue;
    color: #fff;
    position: absolute;
    transform: rotate(-45deg);
    right: -17px;
    top: 29%;
}

// New Card CSS

