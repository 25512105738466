.card_main {
  border: 1px solid #aabbcc;
  display: flex;
  flex-direction: column;
  max-width: 28%;
  width: 100%;
  padding: 7px;
  // height: 100%;
}

.card_heading {
  margin-block: 18px;
  font-size: 26px;
  color: #1c3362;
  text-align: center;
}

.card_price_div {
  display: flex;
  justify-content: center;
  height: 30px;
}
.card_price1 {
  font-size: 14px;
  color: #1c3362;
  padding-top: 5px;
}
.card_price2 {
  font-size: 26px;
  color: #1c3362;
  margin: 0;
  padding: 0;
}
.card_price3 {
  font-size: 14px;
  color: #1c3362;
  line-height: 45px;
  vertical-align: bottom;
}

.card_second {
  border: 1px solid #66666622;
  display: flex;
  flex-direction: column;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fcfcfc),
    to(#e7e7e7)
  );
  border-radius: 6px;
  margin-top: 10px;
  height: 100%;
}

.card_second_heading {
  color: #1c3362;
  border-bottom: 1px dotted #666;
  font-size: 11px;
  margin: 6px;
  padding: 4px;
  align-self: center;
  // text-overflow: clip;
  flex: 1;
  display: flex;
  // word-wrap: break-word;
  // word-break: break-all;
  white-space: pre-line;
  text-align: center;
}

.card_second_heading:last-child {
  border-bottom: none;
  flex: 1;
}

.card_button {
  padding: 5px 10px;
  font-size: 13px;
  border: none;
  border-radius: 16px;
  background-color: #044ea0;
  cursor: pointer;
  color: #fff;
  display: flex;
  font-weight: 400 !important;
  font-size: 14px;
  text-transform: uppercase;
  padding: 12px 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  align-self: center;
}

.card_button:hover {
  background-color: #055ab9;
}

.card_button:active {
  background-color: #034287;
}

.card_button:disabled {
  background-color: #777;
  cursor: default;
}

@media screen and (max-width: 1200px) {
  .card_main {
    max-width: 250px;
    // background-color: green;
  }
}
